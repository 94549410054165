import logo from '../../assets/C2Logo.png';
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Input, Button, Typography } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import { loginUser } from '../../redux/actions/Admin/LoginPageActions';
import './App.css';
import * as React from 'react';
import { useState } from 'react';
import store from '../../store';
import AdminPanel from '../AdminPanel';

function AdminLandingPage(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showAdminPanel, setShowAdminPanel] = useState(false);

    const handleLogin = async () => {

        let result = await store.dispatch(loginUser({UserIdentity: email, Password: password}));

        if(result !== undefined && result !== null){
            setShowAdminPanel(true);
        }

    }

    const handleEmailInput = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordInput = (e) => {
        setPassword(e.target.value);
    }

    const adminLogin = <div className="App">
                            <header className="App-header">
                                <img src={logo} className="App-logo" alt="logo" />
                                <Typography>ADMINISTRATION</Typography>
                                <div className="LoginFields">
                                <TextField sx={{ m: 1, width: '25ch' }} id="standard-basic" label="Username" variant="standard" onChange={handleEmailInput}/>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={'password'}
                                    onChange={handlePasswordInput}
                                />
                                </FormControl>
                                <Button 
                                    sx={{ m: 1, width: '25ch' }}  
                                    variant="contained" 
                                    onClick={handleLogin}
                                >
                                    Login
                                </Button>
                                </div>
                            </header>
                            </div>;
    
    const adminPanel = <AdminPanel />

    return(!showAdminPanel ? adminLogin : adminPanel);

}

export default AdminLandingPage;