import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTestimonials } from "../redux/actions/TestimonialActions";
import { Grid, Card } from "@mui/material";

import "./OurStoryMainPage.css";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function OutStoryMainPage(){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchTestimonials();              
    }, [])
    
    const doFetchTestimonials = async () => {
      dispatch(getTestimonials());
    }


    return(
        <ThemeProvider theme={theme}>  

             <NavigationBar bgcolor="L"/>
             <div id="page-container">  
             <div id="content-wrap">
             <div className="ourstoryList">
             <Grid xs={12} alignItems={"center"} justifyContent={"center"} >
             <Grid item align={"center"}>
            <Card sx={{ width: "50%" }}>
             <p>Welcome to C2 Custom Building, where we are dedicated to building homes that are tailored to your unique needs and preferences. 
                Our team of experienced professionals is passionate about creating beautiful and functional homes that exceed your expectations. 
                We understand that building a home is a significant investment, and we strive to provide you with a seamless and stress-free experience that is second to none.</p>

              <p>At C2 Custom Building, we believe that every home should be built with the best construction practices and materials available. We take pride in our workmanship 
                and attention to detail, and we are committed to delivering high-quality homes that stand the test of time. With over 90 years of combined experience in the industry, 
                we have the knowledge and expertise to handle any project, no matter how complex.</p>

                <p>Our services include design, construction, and project management, and we work closely with you to ensure that your vision is brought to life. We understand that every 
                   client is unique, and we take the time to listen to your needs and preferences to create a home that is truly one-of-a-kind. Whether you are looking to build a new home 
                   from scratch or tear down you’re existing one and build new, we are here to help you every step of the way.</p>

                <p>We are committed to providing you with the highest level of customer service, and we pride ourselves on our open and transparent communication. We believe that communication 
                   is key to a successful project, and we keep you informed every step of the way. We are always available to answer any questions you may have, and we are dedicated to ensuring 
                   that you are completely satisfied with the end results.</p>

                <p>Thank you for considering C2 Custom Building for your home building needs. We look forward to working with you to create the home of your dreams!</p>

        </Card>
        </Grid>
             </Grid></div></div>
             <div id="footer">
             <Footer /></div>
        </div>
        </ThemeProvider>
    );
    
    }
    
export default OutStoryMainPage;