// -------------------------------------
// BEGIN Login Flow State Action Types
// -------------------------------------
// Session Type Flags
export const SET_IS_TRIAL_SESSION = "SET_IS_TRIAL_SESSION";
export const SET_IS_FULL_CUSTOMER = "SET_IS_FULL_CUSTOMER";
export const SET_SHOW_DIAG_TOOLS = "SET_SHOW_DIAG_TOOLS";

// Session Token
export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";
export const UNSET_SESSION_TOKEN = "UNSET_SESSION_TOKEN";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";

// Login Actions
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//Build Actions
export const GET_BUILDS_BEGIN = "GET_BUILDS_BEGIN";
export const GET_BUILDS_FAILURE = "GET_BUILDS_FAILURE";
export const GET_BUILDS_SUCCESS = "GET_BUILDS_SUCCESS";

export const GET_CATEGORY_BUILDS_BEGIN = "GET_CATEGORY_BUILDS_BEGIN";
export const GET_CATEGORY_BUILDS_FAILURE = "GET_CATEGORY_BUILDS_FAILURE";
export const GET_CATEGORY_BUILDS_SUCCESS = "GET_CATEGORY_BUILDS_SUCCESS";

export const SET_SELECTED_BUILD = "SET_SELECTED_BUILD";

export const ADD_TO_BUILDS = "ADD_TO_BUILDS";

export const UPDATE_BUILD_BEGIN = "UPDATE_BUILD_BEGIN";
export const UPDATE_BUILD_FAILURE = "UPDATE_BUILD_FAILURE";
export const UPDATE_BUILD_SUCCESS = "UPDATE_BUILD_SUCCESS";

export const ADD_BUILD_BEGIN = "ADD_BUILD_BEGIN";
export const ADD_BUILD_FAILURE = "ADD_BUILD_FAILURE";
export const ADD_BUILD_SUCCESS = "ADD_BUILD_SUCCESS";

export const DEL_BUILD_BEGIN = "DEL_BUILD_BEGIN";
export const DEL_BUILD_FAILURE = "DEL_BUILD_FAILURE";
export const DEL_BUILD_SUCCESS = "DEL_BUILD_SUCCESS";

export const SET_ADDEDIT_BUILD_NAME = "SET_ADDEDIT_BUILD_NAME";

// Client Actions
export const GET_CLIENTS_BEGIN = "GET_CLIENTS_BEGIN";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";

export const SET_SELECTED_SUBFORM_CLIENT = "SET_SELECTED_SUBFORM_CLIENT";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";

export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";
export const ADD_CLIENT_BEGIN = "ADD_CLIENT_BEGIN";

export const DEL_CLIENT_SUCCESS = "DEL_CLIENT_SUCCESS";
export const DEL_CLIENT_FAILURE = "DEL_CLIENT_FAILURE";
export const DEL_CLIENT_BEGIN = "DEL_CLIENT_BEGIN";

export const UPDATE_CLIENT_BEGIN = "UPDATE_CLIENT_BEGIN";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";

//Gallery Category Actions
export const GET_GC_BEGIN = "GET_GC_BEGIN";
export const GET_GC_FAILURE = "GET_GC_FAILURE";
export const GET_GC_SUCCESS = "GET_GC_SUCCESS";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

export const ADD_CATEGORY_BUILDS_BEGIN = "ADD_CATEGORY_BUILDS_BEGIN";
export const ADD_CATEGORY_BUILDS_FAILURE = "ADD_CATEGORY_BUILDS_FAILURE";
export const ADD_CATEGORY_BUILDS_SUCCESS = "ADD_CATEGORY_BUILDS_SUCCESS";

export const DEL_CATEGORY_BUILDS_BEGIN = "ADD_CATEGORY_BUILDS_BEGIN";
export const DEL_CATEGORY_BUILDS_FAILURE = "ADD_CATEGORY_BUILDS_FAILURE";
export const DEL_CATEGORY_BUILDS_SUCCESS = "ADD_CATEGORY_BUILDS_SUCCESS";

//Testimonial Actions
export const GET_TESTIMONIALS_BEGIN = "GET_TESTIMONIALS_BEGIN";
export const GET_TESTIMONIALS_FAILURE = "GET_TESTIMONIALS_FAILURE";
export const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
export const SET_SELECTED_TESTIMONIAL = "SET_SELECTED_TESTIMONIAL";

//Image Actions
export const SET_IMAGES_FOR_UPLOAD = "SET_IMAGES_FOR_UPLOAD";
export const UPLOAD_IMAGES_START = "UPLOAD_IMAGES_START";
export const UPLOAD_IMAGES_FAILURE = "UPLOAD_IMAGES_FAILURE";
export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS";
export const GET_IMAGES_FOR_BUILD_ID_BEGIN = "GET_IMAGES_FOR_BUILD_ID_BEGIN";
export const GET_IMAGES_FOR_BUILD_ID_SUCCESS = "GET_IMAGES_FOR_BUILD_ID_SUCCESS";
export const GET_IMAGES_FOR_BUILD_ID_FAILURE = "GET_IMAGES_FOR_BUILD_ID_FAILURE";
export const ADD_EDIT_TESTIMONIAL_BEGIN = "ADD_EDIT_TESTIMONIAL_BEGIN";
export const ADD_EDIT_TESTIMONIAL_FAILURE = "ADD_EDIT_TESTIMONIAL_FAILURE";
export const ADD_EDIT_TESTIMONIAL_SUCCESS = "ADD_EDIT_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL_BEGIN = "DELETE_TESTIMONIAL_BEGIN";
export const DELETE_TESTIMONIAL_FAILURE = "DELETE_TESTIMONIAL_FAILURE";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCCESS";
export const ADD_IMAGES_BEGIN = "ADD_IMAGES_BEGIN";
export const ADD_IMAGES_SUCCESS = "ADD_IMAGES_SUCCESS";
export const ADD_IMAGES_FAILURE = "ADD_IMAGES_FAILURE";
export const GET_LANDING_PAGE_PHOTOS_BEGIN = "GET_LANDING_PAGE_PHOTOS_BEGIN";
export const GET_LANDING_PAGE_PHOTOS_SUCCESS = "GET_LANDING_PAGE_PHOTOS_SUCCESS";
export const GET_LANDING_PAGE_PHOTOS_FAILURE = "GET_LANDING_PAGE_PHOTOS_FAILURE";
export const RESET_LAST_PHOTOS_ADDED_METADATA = "RESET_LAST_PHOTOS_ADDED_METADATA";
export const STORE_CACHED_IMAGES = "STORE_CACHED_IMAGES";
export const CLEAR_LASTADDEDPHOTOS_MODE = "CLEAR_LASTADDEDPHOTOS_MODE";
export const REM_IMAGES_BEGIN = "REM_IMAGES_BEGIN";
export const REM_IMAGES_SUCCESS = "REM_IMAGES_SUCCESS";
export const REM_IMAGES_FAILURE = "REM_IMAGES_FAILURE";

//Config Reducer
export const SET_USE_CATEGORIES_IN_GALLERY = "SET_USE_CATEGORIES_IN_GALLERY";
export const GET_CONFIG_BEGIN = "GET_CONFIG_BEGIN";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";
export const SET_CONFIG_BEGIN = "SET_CONFIG_BEGIN";
export const SET_CONFIG_SUCCESS = "SET_CONFIG_SUCCESS";
export const SET_CONFIG_FAILURE = "SET_CONFIG_FAILURE";