import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove'
import { Dialog, TextField, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClientSelector from '../Client/ClientSelector';
import GalleryCategorySelector from '../GalleryCategories/GalleryCategorySelector';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import ImageUploader from '../Images/ImageUploader';
import { updateBuild, addBuild, delBuild, setSelectedBuild, setAddEditBuildName } from '../../../redux/actions/BuildActions';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImages } from '../../../redux/actions/ImageActions';
import { OtherConstants } from '../../../constants/OtherConstants';
import ImageMetaDataCollector from '../Images/ImageMetaDataCollector';
import { setSelectedSubFormClient } from '../../../redux/actions/ClientActions';

const BuildAdderEditor = (props) => {

  const dispatch = useDispatch();

  const isEdit = props.mode === "Edit";

  const images = useSelector((state) => state.imageUploaderReducer.images);
  const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);
  const selectedClient = useSelector((state) => state.clientReducer.selectedSubFormClient);
  const selectedCategory = useSelector((state) => state.galleryCategoryReducer.selectedCategory);
  const builds = useSelector((state) => state.buildReducer.builds);
  const clients = useSelector((state) => state.clientReducer.clients);
  const lastAddedPhotosMetaData = useSelector((state) => state.imageReducer.lastAddedPhotosMetaData);
  const lastAddedPhotosMode = useSelector((state) => state.imageReducer.lastAddedPhotosMode);
  const addEditBuildName = useSelector((state) => state.buildReducer.currentAddEditBuildName);

  const [showForm, setShowForm] = useState(false);
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [showCollectImageMetaData, setShowCollectImageMetaData] = useState(false)
  const [newBuildNameValue, setNewBuildNameValue] = useState(selectedBuild && isEdit ? selectedBuild.name : "")

  const newBuildName = useRef();

    useEffect(() => {
        if(!selectedBuild) return;
        if(isEdit){
            setNewBuildNameValue(selectedBuild.name)
            dispatch(setSelectedSubFormClient(clients.filter(x => x.id === selectedBuild.clientId)[0]));
        }
    }, [selectedBuild]);

    useEffect(() => { 

      if(lastAddedPhotosMode === "ImageAdd") return;

      if(lastAddedPhotosMetaData.length !== 0){
        let addBuildBlob = {};

        addBuildBlob["name"] = addEditBuildName;
        addBuildBlob["clientId"] = selectedClient.id;
        addBuildBlob["GalleryCategoryId"] = !selectedCategory ? OtherConstants.EMPTY_GUID : selectedCategory.id;

        if(lastAddedPhotosMode === props.mode && props.mode === "Add"){
            addBuildBlob["Photos"] = lastAddedPhotosMetaData;
            dispatch(addBuild(addBuildBlob))
        }else if(lastAddedPhotosMode === props.mode && props.mode === "Edit"){
            addBuildBlob["photosToAdd"] = lastAddedPhotosMetaData;
            addBuildBlob["id"] = selectedBuild.id
            dispatch(updateBuild(addBuildBlob));
        }
      }
    }, [lastAddedPhotosMetaData])

    const handleClose = () => {
      setNewBuildNameValue(isEdit && selectedBuild ? selectedBuild.name : "")
  
      dispatch(setSelectedSubFormClient(clients.filter(x => x.id === selectedBuild.clientId)[0]));
    
      setShowForm(false);
      setShowDelConfirmation(false);
    };

    const handleImageMetaDataClose = () => {
        setShowCollectImageMetaData(false);
    }

    const handleAddEdit = async () => {
      
      await dispatch(uploadImages());    

      if(images.length !== 0){
        setNewBuildNameValue(newBuildName.current.value)
        dispatch(setAddEditBuildName(newBuildName.current.value))
        setShowCollectImageMetaData(true);
      }

      if(isEdit){
        let addBuildBlob = {};
        //Add Build data into blob
        addBuildBlob["name"] = newBuildName.current.value;
        addBuildBlob["clientId"] = selectedClient.id;
        addBuildBlob["galleryCategoryId"] = selectedBuild.galleryCategoryId === selectedCategory.id ? selectedBuild.galleryCategoryId : selectedCategory.id;
        addBuildBlob["photosToAdd"] = lastAddedPhotosMetaData;
        addBuildBlob["id"] = selectedBuild.id
        dispatch(updateBuild(addBuildBlob));
      }

      setShowForm(false);
    }

    const handleDelConfirmation = async () => {
          dispatch(await delBuild(selectedBuild.id))
          dispatch(setSelectedBuild(builds[0]))
          setShowDelConfirmation(false)
    }

    const handleClick = () => {

      if(props.mode === "Del"){
        setShowDelConfirmation(true);
      }else{
        setShowForm(true);
      }
    }

    const processNameChange = (event, value) => {
      setNewBuildNameValue(value); 
    }

    const delConfirmation = <Dialog open={showDelConfirmation} onClose={handleClose}>
                                <DialogTitle>{"Confirm Deletion of Build"}</DialogTitle>
                                <DialogContent>
                                  <Typography>Please confirm you wish to delete the build.  Deleting this build will delete the associated Testimonial. This cannot be undone.</Typography>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleDelConfirmation}>{"DELETE"}</Button>
                                </DialogActions>
                            </Dialog>

    const buildAddEditForm = <Dialog open={showForm} onClose={handleClose}>
                                <DialogTitle>{props.mode === "Add" ? "Add" : "Edit"}{" A Build"}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                          inputRef={newBuildName}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Name"
                                          type="search"
                                          value={newBuildNameValue}
                                          onChange={processNameChange}
                                        />
                                    <ClientSelector isEdit={props.mode === "Edit"} isSubForm={true} currentClient={selectedClient}/>
                                    <GalleryCategorySelector isEdit={props.mode === "Edit"} />
                                    <ImageUploader />
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleAddEdit}>{props.mode === "Add" ? "Add" : "Edit"}</Button>
                                </DialogActions>
                            </Dialog>



    return(
            <div>
              <div onClick={() => handleClick()}>{props.mode === "Add" ? <AddIcon /> : props.mode === "Edit" ? <EditIcon /> : <RemoveIcon />}</div>
              {showDelConfirmation ? delConfirmation : <div />}
              {showForm ? buildAddEditForm : <div />}
              {showCollectImageMetaData ? <ImageMetaDataCollector mode={props.mode === "Add" ? "Add" : "Edit"} source={"Build"} images={images} closeFunc={handleImageMetaDataClose} forTestimonial={false}/> : <div></div>}
            </div> 
    );
}

export default BuildAdderEditor;