
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove'
import { Dialog, TextField, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClientSelector from '../Client/ClientSelector';

import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AddEditTestimonial, DeleteTestimonial, getTestimonials, setSelectedTestimonial } from '../../../redux/actions/TestimonialActions';

import { inputLabelClasses } from "@mui/material/InputLabel";

const TestimonialAdderEditor = (props) => {

    const [showForm, setShowForm] = useState(false);
    const [showConfirmDeleteTestimonialForm, setShowConfirmDeleteTestimonialForm] = useState(false)
    const [showBuildAlreadyHasTestimonialForm, setShowBuildAlreadyHasTestimonialForm] = useState(false)
    const [showNoTestimonialsToDelete, setShowNoTestimonialsToDelete] = useState(false)
    const [newlyAddedT, setNewlyAddedT] = useState(null)

    const selectedTestimonial = useSelector((state) => state.testimonialReducer.selectedTestimonial);
    const testimonials = useSelector((state) => state.testimonialReducer.testimonials);
    const selectedClient = useSelector((state) => state.clientReducer.selectedClient);
    const noSelectedTestimonial = { id: '' };

    const dispatch = useDispatch();

    const clientSelectorRef = useRef();
    const quoteFieldRef = useRef();

    useEffect(() => {    
      console.log("testimonials updated")  
        if(props.mode === "Add"){
          if(newlyAddedT){
            let testimonialToSet = testimonials.filter((t) => t.id === newlyAddedT.id)[0]
            console.log("Setting Just Added Testimonial: " + JSON.stringify(testimonialToSet))
            dispatch(setSelectedTestimonial(testimonialToSet))  
          }
           
        }

    }, [testimonials])

    const handleClose = () => {
        setShowForm(false);
        setShowConfirmDeleteTestimonialForm(false)
        setShowBuildAlreadyHasTestimonialForm(false)
        setShowNoTestimonialsToDelete(false)
      };

    const handleDelete = async () => {
        await dispatch(DeleteTestimonial(selectedTestimonial.id))
        setShowConfirmDeleteTestimonialForm(false)
        dispatch(setSelectedTestimonial(noSelectedTestimonial))
    }

    const handleAddEdit = async () => {

      if(quoteFieldRef && quoteFieldRef.current){

        var data = {
          "ClientId":selectedClient.id,
          "Quote":quoteFieldRef.current.value
        }

        if(props.mode === "Add"){
            let newT = await dispatch(await AddEditTestimonial("Add", data))
            await dispatch(await getTestimonials())
            console.log("New Testimonial ID: " + newT.id)
            setNewlyAddedT(newT)
        }else if(props.mode === "Edit"){
            console.log("testimonial ID: " + selectedTestimonial.id)
            data = {...data, "Id": selectedTestimonial.id}
            await dispatch(await AddEditTestimonial("Edit", data))
        }else{
            return
        }

        setShowForm(false);

      }
    }

    const noTestimonialsToDeleteForm = <Dialog open={showNoTestimonialsToDelete} onClose={handleClose}>
                                              <DialogTitle></DialogTitle>
                                              <DialogContent>
                                                <Typography>One cannot edit or delete what does not exist!
                                                </Typography>
                                              </DialogContent>
                                              <DialogActions>
                                              <Button onClick={handleClose}>OK</Button>
                                              </DialogActions>
                                          </Dialog>

    const confirmDeleteTestimonialForm = <Dialog open={showConfirmDeleteTestimonialForm} onClose={handleClose}>
                                            <DialogTitle></DialogTitle>
                                            <DialogContent>
                                              <Typography>Are you CERTAIN you want to delete this testimonial?  It cannot be undone.
                                              </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                            <Button onClick={handleDelete}>YES</Button>
                                            <Button onClick={handleClose}>NO</Button>
                                            </DialogActions>
                                        </Dialog>

    const testimonialAddEditForm = <Dialog open={showForm} 
                                           onClose={handleClose} 
                                           sx={{
                                              "& .MuiDialog-container": {
                                                "& .MuiPaper-root": {
                                                  width: "33%",
                                                  maxWidth: "500px",
                                                  height: "33%",
                                                  maxHeight: "500px"  // Set your width here
                                                },
                                              },
                                            }}>
                                <DialogTitle>{props.mode === "Add" ? "Add" : "Edit"}{" A Testimonial"}</DialogTitle>
                                <DialogContent>
                                  {/*<ClientSelector isEdit={props.mode === "Edit"} ref={clientSelectorRef} isSubForm={true}/>*/}
                                  <Typography>{"For Client: "}{props.mode === "Edit" ? (selectedTestimonial ? "The " + selectedTestimonial.lastName + "'s" : "N/A") : (selectedClient ? "The " + selectedClient.lastName + "'s" : "N/A")}</Typography>
                                  <TextField  multiline
                                              minRows={3}
                                              helperText="Enter a Quote"
                                              label="Quote"
                                              sx={{marginTop: "10px", width: "100%"}}
                                              InputLabelProps={{
                                                sx: {
                                                  color: "black",
                                                  [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "black"
                                                  }
                                                }
                                              }}
                                            defaultValue={selectedTestimonial == null ? "" : selectedTestimonial?.quote} 
                                            inputRef={quoteFieldRef}>
                                  </TextField>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleAddEdit}>{props.mode === "Add" ? "Add" : "Edit"}</Button>
                                </DialogActions>
                            </Dialog>
    
    const buildAlreadyHasTestimonialForm = <Dialog open={showBuildAlreadyHasTestimonialForm} onClose={handleClose}>
                                  <DialogTitle></DialogTitle>
                                  <DialogContent>
                                    <Typography>This build already has a Testimonial.  Only one Testimonial is allowed per build.  Please
                                          delete this Testimonial if you would like to create a new one, or use the edit feature to change 
                                          this testimonial.
                                    </Typography>
                                  </DialogContent>
                                  <DialogActions>
                                  <Button onClick={handleClose}>OK</Button>
                                  </DialogActions>
                              </Dialog>

    const handleClick = () => {

      if((props.mode === "Del" || props.mode === "Edit") && 
         (!selectedTestimonial || selectedTestimonial.id === '')){
        setShowNoTestimonialsToDelete(true)
        return;
      }

      if(!selectedTestimonial){
        setShowForm(true)
        return;
      }

      if(props.mode === "Add" && selectedTestimonial.id !== ''){
        setShowBuildAlreadyHasTestimonialForm(true)
        return;
      }

      if(props.mode === "Del" && selectedTestimonial.id !== ''){
        
        setShowConfirmDeleteTestimonialForm(true)
        return;
      }

      setShowForm(true)
    }

    return(
            <div>
              <div onClick={() => handleClick()}>{props.mode === "Add" ? <AddIcon /> : props.mode === "Edit" ? <EditIcon /> : <RemoveIcon />}</div>
              {showForm ? testimonialAddEditForm : <div />}
              {showConfirmDeleteTestimonialForm ? confirmDeleteTestimonialForm : <div />}
              {showBuildAlreadyHasTestimonialForm ? buildAlreadyHasTestimonialForm : <div />}
              {showNoTestimonialsToDelete ? noTestimonialsToDeleteForm : <div />}
            </div> 
    );
}

export default TestimonialAdderEditor;