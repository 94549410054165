import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from "../../../store";
import { setSelectedTestimonial, getTestimonials } from "../../../redux/actions/TestimonialActions";

function TestimonialSelector(){

  const dispatch = useDispatch();

  const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);
  const selectedTestimonial = useSelector((state) => state.testimonialReducer.selectedTestimonial);
  const testimonials = useSelector((state) => state.testimonialReducer.testimonials);

  const noSelectedTestimonial = { id: '' };
  const [selectedTestimonialLocal, setSelectedTestimonialLocal] = useState(noSelectedTestimonial);

  useEffect(() => {
  }, [testimonials])

  /*useEffect(() => {
    dispatch(getTestimonials());
  }, [selectedTestimonial])*/

  useEffect(() => {
    setTestimonialForBuildFunc(selectedBuild?.id)             
  }, [selectedBuild])

  const setTestimonialForBuildFunc = (id) => {

      if(id === undefined || id == null) return;
      if(!testimonials) return;

      let testimonial = testimonials.filter(testimonial => testimonial.buildId === id)[0]

      setSelectedTestimonialLocal(testimonial)
      dispatch(setSelectedTestimonial(testimonial))
  }


  const clientSelected = async (testimonial) => {
    setSelectedTestimonialLocal(testimonial);
    dispatch(setSelectedTestimonial(testimonial))
  }

  return(
    <FormControl sx={{ m: 1, minWidth: "80%" }}>
        <InputLabel color="primary" id="demo-simple-select-helper-label">{testimonials && testimonials.length > 0 ? "Testimonial" : "No Testimonials"}</InputLabel>
        <Select      
           sx={{
            color: "white",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '.MuiSvgIcon-root ': {
              fill: "white !important",
            }
          }}

          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={!selectedTestimonial ? (selectedTestimonialLocal ? selectedTestimonialLocal.id : "") : selectedTestimonial.id}
          label={testimonials && testimonials.length > 0 ? "Testimonial" : "No Testimonials"}
        >
          {testimonials && testimonials.length > 0 ? testimonials.map((testimonial) =>
                    <MenuItem
                      value={testimonial.id}
                      key={testimonial.id}
                      onClick={() => {clientSelected(testimonial)}}>
                        {"The "}{testimonial.lastName + "'s"} 
                    </MenuItem>
                  ) : <MenuItem
                      value={-1}
                      key={-1}
                      >
                         {"None"}
                      </MenuItem>}
        </Select>
      </FormControl>
      );

}

export default TestimonialSelector;