import { Card, CardMedia, CardContent, Typography, CardHeader } from '@mui/material';
import { ConnectionStrings } from '../constants/ConnectionStrings';

function Testimonial(props){

    const adjustSourceURI = (uri) => {

        if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
            return uri;
        }else{
            //Try to make it a jpg
            return uri+".jpg";
        }
      }


    return(
        <Card raised sx={{marginTop: -10  }}>
            <CardHeader
                title={"The " + props.data.lastName + "'s"}
                sx={{textAlign: "left"}}
            />
            <CardMedia
                sx={{ height: 300 }}
                //image={props.data.photoFileNameHR.endsWith(".jpg") ? ConnectionStrings.Services + "/" + props.data.photoFileNameHR : ConnectionStrings.Services + "/" + props.data.photoFileNameHR + ".jpg"}
                image={`${ConnectionStrings.Services}/` + adjustSourceURI(props.data.photoFileNameHR)}
                title="Happy Clients"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" sx={{fontSize: 14}}>
                {"\"" + props.data.quote + "\""}
                </Typography>
            </CardContent>
        </Card>
    )

}

export default Testimonial;