import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getImagesForBuildId } from '../../../redux/actions/ImageActions';
import { ConnectionStrings } from '../../../constants/ConnectionStrings';
import PhotoAdderEditor from './PhotoAdderEditor';

function ImageNavigator(){

    const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);
    const photosForSelectedBuild = useSelector((state) => state.imageReducer.photosForSelectedBuild);
    const dispatch = useDispatch();

    useEffect(() => {

        if(selectedBuild === undefined || selectedBuild === null)
            return;

        doFetchImages();         

  }, [selectedBuild])

  const doFetchImages = () => {
    dispatch(getImagesForBuildId(selectedBuild.id));
  }

  const adjustSourceURI = (uri) => {

    if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
        return uri;
    }else{
        //Try to make it a jpg
        return uri+".jpg";
    }
  }


    return (
      <div>
      <Box sx={{display: "flex", justifyContent: "right", paddingRight: "2%"}}><PhotoAdderEditor mode="Add" /></Box>
      <Box border={1} borderRadius={2} sx={{margin: "5px", padding: "5px"}}>
        <ImageList sx={{ height: "75vh", '&::-webkit-scrollbar': { display: 'none' } }}>
          {photosForSelectedBuild.map((item) => (
            <ImageListItem key={item} cols={2} rows={1}>
              <Box sx={{display: "flex", justifyContent: "right"}}>
                <PhotoAdderEditor mode="Edit" imageFileNameToEdit={item}></PhotoAdderEditor>
                <PhotoAdderEditor mode="Del" buildId={selectedBuild.id} photoName={item} ></PhotoAdderEditor>
              </Box>
              <img
                src={`${ConnectionStrings.Services}\\${adjustSourceURI(item)}`}
                alt={item}
                loading="lazy"
              />
              <ImageListItemBar
                title={item}
                subtitle={<span>by: {item}</span>}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
        </Box>
        </div>
      );

}


export default ImageNavigator;