import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTestimonials } from "../redux/actions/TestimonialActions";
import { Grid, Card, CardMedia, CardContent, CardHeader, Typography } from "@mui/material";
import { ConnectionStrings } from "../constants/ConnectionStrings";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function TeamMember(props){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchTestimonials();              
    }, [])
    
    const doFetchTestimonials = async () => {
      dispatch(getTestimonials());
    }

    const adjustSourceURI = (uri) => {

        if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
            return uri;
        }else{
            //Try to make it a jpg
            return uri+".jpg";
        }
      }


    return(
        <Card raised sx={{marginTop: 25, maxWidth: 500 }}>
            <CardHeader
                title={props.fullName + ", " + props.title}
                sx={{textAlign: "left"}}
            />
            <CardMedia
                sx={{ height: 300 }}
                //image={props.data.photoFileNameHR.endsWith(".jpg") ? ConnectionStrings.Services + "/" + props.data.photoFileNameHR : ConnectionStrings.Services + "/" + props.data.photoFileNameHR + ".jpg"}
                image={`${ConnectionStrings.Services}/` + props.pic}
                title="Happy Clients"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" sx={{fontSize: 14, textAlign: "left"}}>
                {props.bio}
                </Typography>
            </CardContent>
        </Card>
    );
    
    }
    
export default TeamMember;