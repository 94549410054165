import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from "../../../store";
import { setSelectedClient } from "../../../redux/actions/ClientActions";
import { getClients } from "../../../redux/actions/ClientActions";

function ClientInfo(){

    const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);
    const dispatch = useDispatch();

    const [clientForBuild, setClientForBuild] = useState(null);
    const selectedClient = useSelector((state) => state.clientReducer.selectedClient);
    const clients = useSelector((state) => state.clientReducer.clients);
    
    useEffect(() => { 
          dispatch(getClients());
          setClientForBuild(selectedClient ? selectedClient : (selectedBuild ? clients.filter(c => selectedBuild.clientId === c)[0] : null))         
          
    }, [selectedClient])

    
    return(
    <div><Paper style={{display: 'inline-block', width: '80%'}} elevation={3}>
            <Typography sx={{padding: "5px"}} align="left">{"Client Name: "}{clientForBuild == null ? "N/A" : clientForBuild?.firstName + " " + clientForBuild.lastName}</Typography>
            <Typography sx={{padding: "5px"}} align="left">{"Client Address: "}{clientForBuild == null ? "N/A" : clientForBuild?.streetAddress + ", " + clientForBuild.city + ", " + clientForBuild.zipCode}</Typography>
            <Typography sx={{padding: "5px"}} align="left">{"Phone Number: "}{clientForBuild == null ? "N/A" : clientForBuild?.phoneNumber}</Typography>
            <Typography sx={{padding: "5px"}} align="left">{"Email Address: "}{clientForBuild == null ? "N/A" : clientForBuild?.emailAddress}</Typography>
         </Paper></div>
      );

}

export default ClientInfo;