
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { Dialog, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';
import { setImagesForUpload } from "../../../redux/actions/ImageActions";
import { useDispatch } from 'react-redux';

function ImageUploader(props){

    const [imagesForUpload, updateImagesForUpload] = useState([]);
    const [fileTypeNotSupported, setFileTypeNotSupported] = useState(false);
    const dispatch = useDispatch();

    const areFileTypesValid = (files) => {

      let badFileTypePresent = false;

      files.forEach((f) => {

        let fileName = f.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile !== "jpg" && extFile !== "jpeg" && extFile !== "png"){
            badFileTypePresent = true;
        }
      })

      if(badFileTypePresent){
        setFileTypeNotSupported(true);
        return false;
      }else{
        return true;
      }
    }

    const handleFileSelection = ({ target }) => {

        let temp = [];
        let counter = 0;
        while(counter < target.files.length){
          temp.push(target.files[counter])
          counter++;
        }

        if(!areFileTypesValid(temp)){ console.log("File Types are invalid"); return; };

        updateImagesForUpload(arr => arr.concat(temp));
        
      };
    
    const handleClose = () => {
        setFileTypeNotSupported(false);
    };

    useEffect(() => {
      dispatch(setImagesForUpload(imagesForUpload));      
      }, [imagesForUpload])


      const fileTypeNotSupportedDialog = <Dialog open={fileTypeNotSupported} onClose={handleClose}>
                                              <DialogTitle>{"Confirm Deletion of Client"}</DialogTitle>
                                              <DialogContent>
                                                <Typography>Please only supply jpg/jpeg or png image files, no other file types are supported</Typography>
                                              </DialogContent>
                                              <DialogActions>
                                              <Button onClick={handleClose}>OK</Button>
                                              </DialogActions>
                                          </Dialog>

    return(<div>
        <input
        accept="image/png, image/jpeg"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple={props.allowOnlyOne ? false : true}
        type="file"
        onChange={handleFileSelection}
      />
      <label htmlFor="raised-button-file">
        <Button variant="raised" component="span">
          {props.text ? props.text : "Add an Image"}
        </Button>
      </label> 
        <List dense={true}>
        {imagesForUpload.map((image) =>
          <ListItem
            key={uuid()}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={image.name}
            />
          </ListItem>,
            )}
      </List>
      {fileTypeNotSupported ? fileTypeNotSupportedDialog : <div />}
      </div>
      );

}

export default ImageUploader;