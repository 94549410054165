import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove'
import { Dialog, TextField, Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { addClient, delClient, updateClient, setSelectedClient } from '../../../redux/actions/ClientActions';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import ImageUploader from '../Images/ImageUploader';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImages } from '../../../redux/actions/ImageActions';
import { addImages } from '../../../redux/actions/ImageActions';

const ClientAdderEditor = (props) => {

    const isEdit = props.mode === "Edit";

    const images = useSelector((state) => state.imageUploaderReducer.images);
    const clients = useSelector((state) => state.clientReducer.clients);
    const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);
    const selectedClient = useSelector((state) => state.clientReducer.selectedClient);

    const [newClientFNameValue, setNewClientFNameValue] = useState(selectedClient && isEdit ? selectedClient.firstName : "")
    const [newClientLNameValue, setNewClientLNameValue] = useState(selectedClient && isEdit ? selectedClient.lastName : "")
    const [newClientPNumberValue, setNewClientPNumberValue] = useState(selectedClient && isEdit ? selectedClient.phoneNumber : "")
    const [newClientEmailValue, setNewClientEmailValue] = useState(selectedClient && isEdit ? selectedClient.emailAddress : "")
    const [newClientStreetValue, setNewClientStreetValue] = useState(selectedClient && isEdit ? selectedClient.streetAddress : "")
    const [newClientCityValue, setNewClientCityValue] = useState(selectedClient && isEdit ? selectedClient.city : "")
    const [newClientStateValue, setNewClientStateValue] = useState(selectedClient && isEdit ? selectedClient.state : "")
    const [newClientZipValue, setNewClientZipValue] = useState(selectedClient && isEdit ? selectedClient.zipCode : "")

    const [showForm, setShowForm] = useState(false);
    const [showDelConfirmation, setShowDelConfirmation] = useState(false);
    const [firstNameInputError, setFirstNameInputError] = useState(false);
    const [lastNameInputError, setLastNameInputError] = useState(false);

    const dispatch = useDispatch();

    const imageNameRefs = useRef([]);
    const imageRoomRefs = useRef([]);
    const imageDescriptionRefs = useRef([]);
    const imageFrontPageRefs = useRef([]);
    const imageTestimonialRefs = useRef([]);
    const newClientFirstName = useRef();
    const newClientLastName = useRef();
    const newClientEmail = useRef();
    const newClientPhone = useRef();
    const newClientStreet = useRef();
    const newClientCity = useRef();
    const newClientState = useRef();
    const newClientZip = useRef();

      useEffect(() => {
        if(!selectedClient) return;
        if(isEdit){
            setNewClientFNameValue(selectedClient.firstName)
            setNewClientLNameValue(selectedClient.lastName)
            setNewClientPNumberValue(selectedClient.phoneNumber)
            setNewClientEmailValue(selectedClient.emailAddress)
            setNewClientStreetValue(selectedClient.streetAddress)
            setNewClientCityValue(selectedClient.city)
            setNewClientStateValue(selectedClient.state)
            setNewClientZipValue(selectedClient.zipCode)
        }
    }, [selectedClient]);

    const handleClose = () => {
        setShowForm(false);
        setShowDelConfirmation(false);
        setFirstNameInputError(false);
        setLastNameInputError(false);
    };

    const isInputValid = () => {

      let returnState = true;

      if(newClientFirstName.current.value == null || newClientFirstName.current.value === ""){
        setFirstNameInputError(true);
        returnState = false;
      }

      if(newClientLastName.current.value == null || newClientLastName.current.value === ""){
        setLastNameInputError(true);
        returnState = false;
      }

      return returnState;

    }

    const handleAddEdit = async () => {
      
      if(!isInputValid()){
        return;
      }


      dispatch(await uploadImages());    

      let photoList = [];

      if(images.length > 0){
      let photoMetaData = {Name: images[0].name,
                            FileName: images[0].name,
                            RoomDesignation: "",
                            Description: "",
                            IsForFrontPage: false,
                            IsForTestimonial: true}
      photoList.push(photoMetaData)
      }
      
      let addClientBlob = {};

      //Add Build data into blob
      addClientBlob["firstName"] = newClientFirstName.current.value;
      addClientBlob["lastName"] = newClientLastName.current.value;
      addClientBlob["phoneNumber"] = newClientPhone.current.value;
      addClientBlob["emailAddress"] = newClientEmail.current.value;
      addClientBlob["streetAddress"] = newClientStreet.current.value;
      addClientBlob["city"] = newClientCity.current.value;
      addClientBlob["state"] = newClientState.current.value;
      addClientBlob["zipCode"] = newClientZip.current.value;

      if(photoList.length !== 0){
        addClientBlob["lRPhotoId"] = (await dispatch(await addImages(photoList))).ids[0];
        addClientBlob["hRPhotoId"] = addClientBlob["lRPhotoId"];
      }

      if(props.mode === "Add"){
          dispatch(addClient(addClientBlob))
      }else{
          let masterBlob = {};
          addClientBlob["id"] = selectedClient.id;
          masterBlob["updatedClient"] = addClientBlob;
          dispatch(updateClient(masterBlob));
      }

      setShowForm(false);
    }

    const handleDelConfirmation = async () => {
          dispatch(await delClient(selectedClient.id))
          dispatch(setSelectedClient(clients[0]))
          setShowDelConfirmation(false)
    }

    const handleClick = () => {

      if(props.mode === "Del"){
        setShowDelConfirmation(true);
      }else{
        setShowForm(true);
      }
    }

    const processFNameChange = (event, value) => {
      setNewClientFNameValue(value)
    }

    const processZipChange = (event, value) => {
      setNewClientZipValue(value)
    }

    const processLNameChange = (event, value) => {
      setNewClientLNameValue(value)
    }

    const processStreetChange = (event, value) => {
      setNewClientStreetValue(value)
    }

    const processCityChange = (event, value) => {
      setNewClientCityValue(value)
    }

    const processStateChange = (event, value) => {
      setNewClientStateValue(value)
    }

    const processEmailChange = (event, value) => {
      setNewClientEmailValue(value)
    }

    const processPNumberChange = (event, value) => {
      setNewClientPNumberValue(value)
    }

    const delConfirmation = <Dialog open={showDelConfirmation} onClose={handleClose}>
                                <DialogTitle>{"Confirm Deletion of Client"}</DialogTitle>
                                <DialogContent>
                                  <Typography>Please confirm you wish to delete the Client.  Deleting this Client will delete the associated Testimonial. This cannot be undone.</Typography>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleDelConfirmation}>{"DELETE"}</Button>
                                </DialogActions>
                            </Dialog>

    const clientAddEditForm = <Dialog open={showForm} onClose={handleClose}>
                                <DialogTitle>{props.mode === "Add" ? "Add" : "Edit"}{" A Client"}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                          error={firstNameInputError}
                                          helperText="Required"
                                          inputRef={newClientFirstName}
                                          value={newClientFNameValue}
                                          onChange={processFNameChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="First Name"
                                          type="search"
                                        />
                                    <TextField
                                          error={lastNameInputError}
                                          helperText="Required"
                                          inputRef={newClientLastName}
                                          value={newClientLNameValue}
                                          onChange={processLNameChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Last Name"
                                          type="search"
                                        />
                                     <TextField
                                          inputRef={newClientEmail}
                                          value={newClientEmailValue}
                                          onChange={processEmailChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Email Address"
                                          type="search"
                                        />
                                      <TextField
                                          inputRef={newClientPhone}
                                          value={newClientPNumberValue}
                                          onChange={processPNumberChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Phone Number"
                                          type="search"
                                        />
                                      <TextField
                                          inputRef={newClientStreet}
                                          value={newClientStreetValue}
                                          onChange={processStreetChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Street"
                                          type="search"
                                        />
                                      <TextField
                                          inputRef={newClientCity}
                                          value={newClientCityValue}
                                          onChange={processCityChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="City"
                                          type="search"
                                        />
                                      <TextField
                                          inputRef={newClientState}
                                          value={newClientStateValue}
                                          onChange={processStateChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="State"
                                          type="search"
                                        />
                                      <TextField
                                          inputRef={newClientZip}
                                          value={newClientZipValue}
                                          onChange={processZipChange}
                                          sx={{ m: 1, minWidth: "90%", color: "black" }}
                                          id="outlined-search"
                                          label="Zip Code"
                                          type="search"
                                        />

                                    <ImageUploader allowOnlyOne={true} text={"Add Client Image"}/>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleAddEdit}>{props.mode === "Add" ? "Add" : "Edit"}</Button>
                                </DialogActions>
                            </Dialog>



    return(
            <div>
              <div onClick={() => handleClick()}>{props.mode === "Add" ? <AddIcon /> : props.mode === "Edit" ? <EditIcon /> : <RemoveIcon />}</div>
              {showDelConfirmation ? delConfirmation : <div />}
              {showForm ? clientAddEditForm : <div />}
            </div> 
    );
}

export default ClientAdderEditor;