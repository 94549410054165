import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography } from "@mui/material";
import { SendWarrantyClaim } from "../redux/actions/ContactActions";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

function WarrantyClaimForm(props){

    const [showError, setShowError] = useState(false);
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);

    let dispatch = useDispatch();

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const pnumberRef = useRef();
    const streetRef = useRef();
    const completionRef = useRef();
    const msgRef = useRef();

    const handleCancel = () => {
        props.closeFunc();
    }
    
    const handleErrorCancel = () => {
        setShowError(false);
        setDone(false);
    }

    const handleSend = async () => {


        let blob = {
            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            Email: emailRef.current.value,
            PhoneNumber: pnumberRef.current.value,
            StreetAddress: streetRef.current.value,
            CompletionYear: completionRef.current.value,
            Message: msgRef.current.value
        };

        setLoading(true);
        if(!await dispatch(await SendWarrantyClaim(blob))){
            setShowError(true);
        }else{
            setShowError(false);
        }

        setDone(true);
        setLoading(false);
    }

    const ErrorDialog = <Dialog open={showError} onClose={handleErrorCancel}>
                            <DialogTitle>Unexpected Error</DialogTitle>
                            <DialogContent>
                                <Typography>We do apologize, and unexpected error has occured in transmitting your contact request, please contact us at: 123 456 7890</Typography>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleErrorCancel}>OK</Button>
                            </DialogActions>
                        </Dialog>

    const SuccessDialog = <Dialog open={!showError} onClose={handleErrorCancel}>
                            <DialogTitle>Sent!</DialogTitle>
                            <DialogContent>
                                <Typography>Thank you for your inquiry - we will reach out to you shortly!</Typography>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleCancel}>OK</Button>
                            </DialogActions>
                        </Dialog>

    const ContactForm =  <Dialog open={true} onClose={handleCancel}>
                            <DialogTitle>File a Warranty Claim</DialogTitle>
                            <DialogContent>
                                <TextField
                                      inputRef={firstNameRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="First Name"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={lastNameRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Last Name"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={emailRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Email"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={pnumberRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Phone Number"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={streetRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Street Address"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={completionRef}
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Year Home was Completed"
                                      type="search"
                                    />
                                    <TextField
                                      inputRef={msgRef}
                                      multiline
                                      sx={{ m: 1, minWidth: "90%", color: "black" }}
                                      id="outlined-search"
                                      label="Message"
                                      type="search"
                                    />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleSend}>Send</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                            </DialogActions>
                        </Dialog>
    
    const ProgressDialogue = <Dialog open={true}>
                                <DialogContent>
                                    <CircularProgress variant={"indeterminate"} sx={{zIndex: 12000}}/> 
                                </DialogContent>
                            </Dialog>

    return(
        <div>
          {loading ? ProgressDialogue : <div />}
          {!loading ? ( done ? (showError ? ErrorDialog : SuccessDialog) : ContactForm ) : <div /> }
        </div>
    );

}

export default WarrantyClaimForm;