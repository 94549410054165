import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGalleryCategories, setSelectedCategory } from "../../../redux/actions/GalleryCategoryActions";
import { OtherConstants } from "../../../constants/OtherConstants";

function GalleryCategorySelector(props){

    const dispatch = useDispatch();
    const categories = useSelector((state) => state.galleryCategoryReducer.categories);
    const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);

    const selectedCategoryRef = useRef();

    const noSelectedCategory = { id: '' };
    const [selectedGalleryCategory, setSelectedGalleryCategoryLocal] = useState(noSelectedCategory);

    useEffect(() => {
          doFetchGalleryCategories();   
    }, [categories])

    useEffect(() => {
      dispatch(setSelectedCategory({ id: selectedCategoryRef.current.value})) 
}, [])

    const doFetchGalleryCategories = async () => {
        await dispatch(await getGalleryCategories());

      }

  const GalleryCategorySelected = async (category) => {
    setSelectedGalleryCategoryLocal(category);
    dispatch(setSelectedCategory(category))
  }
    

    return(
    <FormControl sx={{ m: 1, minWidth: "90%" }}>
        <InputLabel id="demo-simple-select-helper-label">Gallery Category</InputLabel>
        <Select
          inputRef={selectedCategoryRef}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={props.isEdit ? (selectedBuild.galleryCategoryId === OtherConstants.EMPTY_GUID ? noSelectedCategory.id : selectedBuild.galleryCategoryId) : selectedGalleryCategory.id}
          label="GalleryCategory"
        >
          {categories?.map((category) =>
                    <MenuItem
                      value={category ? category.id : noSelectedCategory.id}
                      key={category ? category.id : noSelectedCategory.id}
                      onClick={() => {GalleryCategorySelected(category)}}>
                        {category ? category.name : "Select"} 
                    </MenuItem>
                  ) }
        </Select>
        <FormHelperText>Select a Gallery Category</FormHelperText>
      </FormControl>
      );

}

export default GalleryCategorySelector;