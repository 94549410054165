import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionStrings } from '../constants/ConnectionStrings';
import { getConfig } from "../redux/actions/ConfigActions";
import CategoryViewPage from "./CategoryViewPage";

import { Card, CardMedia, CardContent, Typography, CardActionArea, Grid } from "@mui/material";

import nohouse from "../assets/nohouse.png";

import "../components/GalleryMainPage.css";


const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function GalleryMainPage(props){

    const dispatch = useDispatch();
    
    const categories = useSelector((state) => state.galleryCategoryReducer.categories);
    const builds = useSelector((state) => state.buildReducer.builds);
    const configuration = useSelector((state) => state.configReducer.configuration)

    const [showCategoryView, setShowCategoryView] = useState(false)
    const [categoryIdToView, setCategoryIdToView] = useState(null)

    useEffect(() => {
        dispatch(getConfig())  
    }, [])

    const adjustSourceURI = (uri) => {

        if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
            return uri;
        }else{
            //Try to make it a jpg
            return uri+".jpg";
        }
      }

    const processCategoryClick = (categoryId) => {
        setCategoryIdToView(categoryId);
        setShowCategoryView(true);
    }

    return(
        <ThemeProvider theme={theme}>
            <NavigationBar bgcolor="L"/>
            <Grid container spacing={2} width="50%" justifyContent="center" alignItems="center" sx={{marginTop: 20, marginLeft: "auto", marginRight: "auto"}}>
                {configuration && configuration["galleryCategoriesEnabled"] === "1" && !showCategoryView ? categories.map((category) => {
                    
                    let categoryBuilds = builds.filter((item) => item.galleryCategoryId == category.id);

                    return <Grid item xl={6} align="center">
                                    <Card sx={{minWidth: "200px"}} onClick={() => { processCategoryClick(category.id)}}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="150px"
                                                src={categoryBuilds.length === 0 ? nohouse : `${ConnectionStrings.Services}\\${adjustSourceURI(categoryBuilds[0].photoFileNames[0])}`}
                                                title="House"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {category.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                    </Card>
                            </Grid>

                }) : 
                
                <CategoryViewPage id={categoryIdToView} />
                }

            </Grid>
        </ThemeProvider>
    );
    
    }
    
export default GalleryMainPage;