import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addGalleryCategory} from "../../../redux/actions/GalleryCategoryActions";

function GalleryCategoryAdder(props){

    const dispatch = useDispatch();

    const [showAddWindow, setShowAddWindow] = useState(false);
    const newGalleryCategoryRef = useRef();


  const handleAdd = async () => {
    await dispatch(await addGalleryCategory(newGalleryCategoryRef.current.value))
    setShowAddWindow(false);
  }

  const handleClick = () => {
    setShowAddWindow(true);
  }

  const handleClose = () => {
    setShowAddWindow(false);
  }
    
  const addWindow = <Dialog open={showAddWindow} onClose={handleClose}>
  <DialogTitle>{"Add a Gallery Category"}</DialogTitle>
  <DialogContent>
    <TextField label="Name" inputRef={newGalleryCategoryRef}>
    </TextField>
  </DialogContent>
  <DialogActions>
  <Button onClick={handleClose}>Cancel</Button>
  <Button onClick={handleAdd}>{"Add"}</Button>
  </DialogActions>
</Dialog>

    return(
        showAddWindow ? addWindow : <Button  sx={{height: "50px"}} variant="contained" onClick={handleClick}>Add Gallery Category</Button>
      );

}

export default GalleryCategoryAdder;