import { Grid, Box } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import ClientSubPanel from "./Admin/Client/ClientSubPanel";
import BuildSubPanel from "./Admin/Build/BuildSubPanel";
import TestimonialSubPanel from "./Admin/Testimonial/TestimonialSubPanel";
import ImageNavigator from "./Admin/Images/ImageNavigator";
import ConfigPanel from "./Admin/Config/ConfigPanel";

function AdminPanel(){

    const imagesAreLoading = useSelector((state) => state.imageReducer.loading);

    const imagesUploading = <Backdrop sx={{ color: '#fff', zIndex: 99999}} open={true}>
                                <CircularProgress color="inherit" />
                            </Backdrop>;


    return(<div className="App">
    <header className="App-header">
    <Box >
        <Grid container columnSpacing={3}>
            <Grid container item xs={6}>
                <Grid item xs={12} sx={{height: "19%"}}><BuildSubPanel /></Grid>
                <Grid item xs={12} sx={{height: "35%"}}><ClientSubPanel /></Grid>                
                <Grid item xs={12} sx={{height: "25%"}}><TestimonialSubPanel /></Grid>
            </Grid>          
            <Grid container item xs={6}>
                <Grid item xs={12} sx={{height: "15%"}}><ConfigPanel /></Grid>
                <Grid item xs={12} sx={{height: "83%"}}><ImageNavigator /></Grid>
            </Grid>
        </Grid>
    </Box>
    </header>
    {imagesAreLoading ? imagesUploading : <></>}
    </div>);

}

export default AdminPanel;