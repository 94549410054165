import store from '../../store';
import { SET_USE_CATEGORIES_IN_GALLERY, GET_CONFIG_BEGIN, GET_CONFIG_FAILURE, 
         GET_CONFIG_SUCCESS, SET_CONFIG_BEGIN, SET_CONFIG_FAILURE, SET_CONFIG_SUCCESS } from '../actions/actionTypes';
import { ConnectionStrings } from '../../constants/ConnectionStrings';

const setUseCategoriesInGallery = state => ({
    type: SET_USE_CATEGORIES_IN_GALLERY,
    payload: { state }
});

export function getConfig() {
    return async dispatch => {

        dispatch(getConfigBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetConfig/", {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getConfigFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getConfigFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let config = response
            if (config === null || config === undefined) {
                return null;
            } else {
                dispatch(getConfigSuccessInternal(config));
                return config;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getConfigFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getConfigFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const getConfigBeginInternal = () => ({
    type: GET_CONFIG_BEGIN,

});

const getConfigSuccessInternal = (config) => ({
    type: GET_CONFIG_SUCCESS,
    payload: { config }
});

const getConfigFailureInternal = errorMessage => ({
    type: GET_CONFIG_FAILURE,
    payload: { errorMessage }
});

export function setConfig(config) {
    return async dispatch => {

        dispatch(setConfigBeginInternal());

        let state = store.getState();

        let dto = {SessionToken: state.sessionTokenReducer.session_token,
                   Config: config};

        return fetch(ConnectionStrings.Services + "/C2Core/SetConfig/", {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                dispatch(setConfigSuccessInternal(config))
            } else if (response.status === 401) {
                dispatch(setConfigFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(setConfigFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(setConfigFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(setConfigFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const setConfigBeginInternal = () => ({
    type: SET_CONFIG_BEGIN,

});

const setConfigSuccessInternal = (config) => ({
    type: SET_CONFIG_SUCCESS,
    payload: { config }
});

const setConfigFailureInternal = errorMessage => ({
    type: SET_CONFIG_FAILURE,
    payload: { errorMessage }
});