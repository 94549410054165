import { Grid, Box, Typography } from "@mui/material";
import { NavLink, Link } from 'react-router-dom';
import C2LogoW from "../assets/C2LogoW.png";
import C2Logo from "../assets/C2Logo.png";

function Footer(props){

    return(<div>
    
    <Box sx={{width: "100%", height: "100px", backgroundColor: "grey", marginTop: "25px"}}>
       
    </Box>

    </div>);

}

export default Footer;