import BuildSelector from "./BuildSelector";
import BuildAdderEditor from "./BuildAdderEditor";
import ClientInfo from "../Client/ClientInfo";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from "../../../redux/actions/ClientActions";
import { getBuilds } from "../../../redux/actions/BuildActions";
import { Box } from "@mui/material";
import GalleryCategoryAdder from "../GalleryCategories/GalleryCategoryAdder";
import GalleryCategoryDeleter from "../GalleryCategories/GalleryCategoryDeleter";


function BuildSubPanel(){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchClients();              
    }, [])

    const doFetchClients = async () => {
      dispatch(await getClients());
      dispatch(await getBuilds());
    }


    return(
    <Box border={1} borderRadius={2} sx={{margin: "5px", padding: "5px", height: "90%"}}>
        <Box sx={{display: "flex", justifyContent: "right", paddingRight: "10%"}}>
            <BuildAdderEditor mode="Add"/>
            <BuildAdderEditor mode="Edit"/>
            <BuildAdderEditor mode="Del"/>
        </Box>
        <Box sx={{display: "inline-block", justifyContent: "center", minWidth: "80%"}}>
            <BuildSelector />
            <Box sx={{display: "inline-flex", justifyContent: "space-between", minWidth: "500px"}}>
                <GalleryCategoryAdder />
                <GalleryCategoryDeleter />
            </Box>
        </Box>
    </Box>);

}

export default BuildSubPanel;