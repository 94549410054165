import { LOGIN_BEGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from '../../actions/actionTypes';

const initialState = {
    loading: false,
    errorMessage: null
};

export default function loginPageReducer(state = initialState, action) {

    switch(action.type) {
        case LOGIN_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case LOGIN_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                loading: false,
                errorMessage: null
            };

        case LOGIN_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}