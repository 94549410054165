import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSelector, dispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { setSelectedTestimonial } from "../../../redux/actions/TestimonialActions";

function TestimonialInfo(){

    const selectedTestimonial = useSelector((state) => state.testimonialReducer.selectedTestimonial);
    const selectedClient = useSelector((state) => state.clientReducer.selectedClient);
    const testimonials = useSelector((state) => state.testimonialReducer.testimonials);

   /* useEffect(() => {    
         dispatch(setSelectedTestimonial(testimonials.filter(x => x.id === selectedClient.)))
    }, [selectedClient])*/

    return(
    <div><Paper style={{display: 'inline-block', width: '80%'}} elevation={3}>
            <Typography sx={{padding: "5px"}} align="left">{"Quote: "}{selectedTestimonial == null ? "N/A" : selectedTestimonial?.quote}</Typography>
         </Paper></div>
      );

}

export default TestimonialInfo;