import { SET_IMAGES_FOR_UPLOAD } from '../actions/actionTypes';

const initialState = {
    images: [],
    loading: false,
    errorMessage: null
};

export default function imageUploaderReducer(state = initialState, action) {

    switch(action.type) {
        case SET_IMAGES_FOR_UPLOAD:

            return {
                ...state,
                images: action.payload.images,
                errorMessage: null
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}