import store from '../../store';
import { ConnectionStrings } from '../../constants/ConnectionStrings';
import { GET_CLIENTS_BEGIN, GET_CLIENTS_FAILURE, GET_CLIENTS_SUCCESS, 
         SET_SELECTED_CLIENT, ADD_CLIENT_BEGIN, ADD_CLIENT_SUCCESS, 
         ADD_CLIENT_FAILURE, DEL_CLIENT_BEGIN, DEL_CLIENT_FAILURE, DEL_CLIENT_SUCCESS,
        UPDATE_CLIENT_BEGIN, UPDATE_CLIENT_FAILURE, UPDATE_CLIENT_SUCCESS, SET_SELECTED_SUBFORM_CLIENT } from './actionTypes';

export function getClients() {
    return async dispatch => {

        let state = store.getState();

        dispatch(getClientsBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetClients/" + state.sessionTokenReducer.session_token, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getClientsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getClientsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let clients = response?.clients
            if (clients === null || clients === undefined) {
                return null;
            } else {
                dispatch(getClientsSuccessInternal(clients));
                return clients;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getClientsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getClientsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const getClientsBeginInternal = () => ({
    type: GET_CLIENTS_BEGIN,

});

const getClientsSuccessInternal = (clients) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: { clients }
});

const getClientsFailureInternal = errorMessage => ({
    type: GET_CLIENTS_FAILURE,
    payload: { errorMessage }
});

export const setSelectedClient = (client) => ({
    type: SET_SELECTED_CLIENT,
    payload: { client }
})

export const setSelectedSubFormClient = (client) => ({
    type: SET_SELECTED_SUBFORM_CLIENT,
    payload: { client }
})

export function getClientInfoForBuild(id) {
    return async dispatch => {

        let state = store.getState();

        dispatch(getClientsBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetClients/" + state.sessionTokenReducer.session_token, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getClientsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getClientsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let clients = response?.clients
            if (clients === null || clients === undefined) {
                return null;
            } else {
                dispatch(getClientsSuccessInternal(clients));
                return clients;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getClientsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getClientsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export function addClient(clientblob) {
    return async dispatch => {

        dispatch(addClientBeginInternal());

        let state = store.getState();

        clientblob["SessionToken"] = state.sessionTokenReducer.session_token;

        return fetch(ConnectionStrings.Services + "/C2Core/AddClient/", {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(clientblob)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(addClientFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(addClientFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(async function(response) {
            if(response == null){
                return null;
            } else {
                clientblob["id"] = response.id;

                let newBuilds = [];
                newBuilds.push(clientblob);

                dispatch(await addClientSuccessInternal(clientblob));
                dispatch(await setSelectedClient(clientblob))
                return response.id;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting Client update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(addClientFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(addClientFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const addClientBeginInternal = () => ({
    type: ADD_CLIENT_BEGIN,

});

const addClientSuccessInternal = (client) => ({
    type: ADD_CLIENT_SUCCESS,
    payload: { client }
});

const addClientFailureInternal = errorMessage => ({
    type: ADD_CLIENT_FAILURE,
    payload: { errorMessage }
});

export function delClient(id) {
    return async dispatch => {

        dispatch(delClientBeginInternal());

        let state = store.getState();

        let delClientDto = {};

        delClientDto["SessionToken"] = state.sessionTokenReducer.session_token;
        delClientDto["Id"] = id;

        return fetch(ConnectionStrings.Services + "/C2Core/RemoveClient/", {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(delClientDto)
            
        }).then(function(response) {
            if (response.status === 200) {
                return null;
                
            } else if (response.status === 401) {
                dispatch(delClientFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(delClientFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function() {
                dispatch(delClientSuccessInternal(id));
                
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting build update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(delClientFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(delClientFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const delClientBeginInternal = () => ({
    type: DEL_CLIENT_BEGIN,

});

const delClientSuccessInternal = (id) => ({
    type: DEL_CLIENT_SUCCESS,
    payload: { id }
});

const delClientFailureInternal = errorMessage => ({
    type: DEL_CLIENT_FAILURE,
    payload: { errorMessage }
});

export function updateClient(clientBlob) {
    return async dispatch => {

        dispatch(updateClientBeginInternal());

        let state = store.getState();

        clientBlob["SessionToken"] = state.sessionTokenReducer.session_token;

        return fetch(ConnectionStrings.Services + "/C2Core/UpdateClient/", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(clientBlob)
            
        }).then(function(response) {
            if (response.status === 200) {
                dispatch(updateClientSuccessInternal(clientBlob.updatedClient));
            } else if (response.status === 401) {
                dispatch(updateClientFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(updateClientFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting build update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(updateClientFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(updateClientFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const updateClientBeginInternal = () => ({
    type: UPDATE_CLIENT_BEGIN,

});

const updateClientSuccessInternal = (client) => ({
    type: UPDATE_CLIENT_SUCCESS,
    payload: { client }
});

const updateClientFailureInternal = errorMessage => ({
    type: UPDATE_CLIENT_FAILURE,
    payload: { errorMessage }
});