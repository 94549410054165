import store from '../../store';
import { ConnectionStrings } from '../../constants/ConnectionStrings';
import { GET_TESTIMONIALS_BEGIN, GET_TESTIMONIALS_FAILURE, GET_TESTIMONIALS_SUCCESS, 
         SET_SELECTED_TESTIMONIAL, ADD_EDIT_TESTIMONIAL_BEGIN, ADD_EDIT_TESTIMONIAL_SUCCESS,
         ADD_EDIT_TESTIMONIAL_FAILURE, DELETE_TESTIMONIAL_BEGIN, DELETE_TESTIMONIAL_FAILURE,
        DELETE_TESTIMONIAL_SUCCESS } from './actionTypes';

export function getTestimonials() {
    return async dispatch => {

        dispatch(getTestimonialsBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetTestimonials/", {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getTestimonialsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getTestimonialsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let testimonials = response?.testimonials
            dispatch(getTestimonialsSuccessInternal(testimonials));
            if (testimonials === null || testimonials === undefined) {           
                return null;
            } else {
                return testimonials;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getTestimonialsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getTestimonialsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export function AddEditTestimonial(mode, testimonialData) {
    return async dispatch => {

        dispatch(addeditTestimonialBeginInternal());

        var connectionString = mode === "Add" ? "AddTestimonial/" : "UpdateTestimonial/";

        let state = store.getState();

        var dto = {

            "SessionToken": state.sessionTokenReducer.session_token,
            "Id": testimonialData.Id ?? null,
            "ClientId": testimonialData.ClientId,
            "Quote": testimonialData.Quote,
            "BuildId": state.buildReducer.selectedBuild.id
        }

        return fetch(ConnectionStrings.Services + "/C2Core/" + connectionString, {
            method: mode === "Add" ? 'PUT' : "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {

                if(mode === "Add") return response.json();

            } else if (response.status === 401) {
                dispatch(addeditTestimonialsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(addeditTestimonialsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(async function(response) {
            console.log("Inside 2nd THEN")
            if(mode === "Add" && (response == null || response.id == null)){
                return null;
            }

            await dispatch(await getTestimonials())

            let newT = {"id": mode === "Add" ? response.id : testimonialData.Id,
                        "quote": testimonialData.Quote,
                        "clientId": testimonialData.ClientId,
                        "buildId":state.buildReducer.selectedBuild.id}

            dispatch(setSelectedTestimonial(newT));

            dispatch(addeditTestimonialSuccessInternal())

            return response;

        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(addeditTestimonialsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(addeditTestimonialsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export function DeleteTestimonial(id) {
    return async dispatch => {

        dispatch(deleteTestimonialBeginInternal());
        let state = store.getState();

        var dto = {"SessionToken": state.sessionTokenReducer.session_token,
                    "Id": id }

        return fetch(ConnectionStrings.Services + "/C2Core/RemoveTestimonial", {
            method: "DELETE",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return null;
            } else if (response.status === 401) {
                dispatch(deleteTestimonialsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(deleteTestimonialsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null || response.id == null){
                return null;
            }

            dispatch(deleteTestimonialSuccessInternal())
            return null;

        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(deleteTestimonialsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(deleteTestimonialsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export const setSelectedTestimonial = (testimonial) => ({
    type: SET_SELECTED_TESTIMONIAL,
    payload: {testimonial}
});

const getTestimonialsBeginInternal = () => ({
    type: GET_TESTIMONIALS_BEGIN,

});

const getTestimonialsSuccessInternal = (testimonials) => ({
    type: GET_TESTIMONIALS_SUCCESS,
    payload: { testimonials }
});

const getTestimonialsFailureInternal = errorMessage => ({
    type: GET_TESTIMONIALS_FAILURE,
    payload: { errorMessage }
});

const addeditTestimonialBeginInternal = () => ({
    type: ADD_EDIT_TESTIMONIAL_BEGIN,

});

const addeditTestimonialSuccessInternal = () => ({
    type: ADD_EDIT_TESTIMONIAL_SUCCESS
});

const addeditTestimonialsFailureInternal = errorMessage => ({
    type: ADD_EDIT_TESTIMONIAL_FAILURE,
    payload: { errorMessage }
});

const deleteTestimonialBeginInternal = () => ({
    type: DELETE_TESTIMONIAL_BEGIN,

});

const deleteTestimonialSuccessInternal = () => ({
    type: DELETE_TESTIMONIAL_SUCCESS
});

const deleteTestimonialsFailureInternal = errorMessage => ({
    type: DELETE_TESTIMONIAL_FAILURE,
    payload: { errorMessage }
});