import { ADD_CATEGORY_BUILDS_SUCCESS, DEL_CATEGORY_BUILDS_SUCCESS, GET_GC_BEGIN, GET_GC_FAILURE, GET_GC_SUCCESS, SET_SELECTED_CATEGORY } from '../actions/actionTypes';

const initialState = {
    categories: [],
    selectedCategory: null,
    loading: false,
    errorMessage: null
};

export default function galleryCategoryReducer(state = initialState, action) {

    switch(action.type) {

        case SET_SELECTED_CATEGORY:

            console.log("SETTING CAT: " + JSON.stringify(action.payload.category))

            return {
                ...state,
                selectedCategory: action.payload.category,
                loading: false,
                errorMessage: null
            };

        case GET_GC_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_GC_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                categories: action.payload.categories,
                loading: false,
                errorMessage: null
            };

        case GET_GC_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case ADD_CATEGORY_BUILDS_SUCCESS:

            let newCategoryList = state.categories.map(x => x);
            newCategoryList.push(action.payload.newCategory)

            return {
                ...state,
                categories: newCategoryList
            }

        case DEL_CATEGORY_BUILDS_SUCCESS:

            return {
                ...state,
                categories: state.categories.filter(x => x.id !== action.payload.id)
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}