const NO_TIMEOUT_EXPIRATION_IN_S = 60 * 60 * 24 * 365 * 100 // 100 year
const LOGIN_EXPIRATION_IN_S = 30 * 60; // 30 minutes per Intuit Requirement
const TRIAL_EXPIRATION_IN_S = 15 * 60; // 15 minutes

module.exports.SessionConstants = Object.freeze({

    LOGIN_EXPIRATION_IN_MS: LOGIN_EXPIRATION_IN_S * 1000,
    TRIAL_EXPIRATION_IN_MS: TRIAL_EXPIRATION_IN_S * 1000,
    NO_TIMEOUT_EXPIRATION_IN_MS: NO_TIMEOUT_EXPIRATION_IN_S * 1000

});