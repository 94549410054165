import {combineReducers} from "redux";
import loginPageReducer from "./Admin/LoginPageReducer";
import sessionTokenReducer from "./Admin/SessionTokenReducer";
import buildReducer from "./BuildReducer";
import clientReducer from "./ClientReducer";
import galleryCategoryReducer from "./GalleryCategoryReducer";
import testimonialReducer from "./TestimonialReducer";
import imageUploaderReducer from "./ImageUploaderReducer";
import imageReducer from "./ImageReducer";
import configReducer from "./ConfigReducer";

const appReducer = combineReducers({
    loginPageReducer,
    sessionTokenReducer,
    buildReducer,
    clientReducer,
    galleryCategoryReducer,
    testimonialReducer,
    imageUploaderReducer,
    imageReducer,
    configReducer
})

export const rootReducer = (state, action) => {
    return appReducer(state, action);
}