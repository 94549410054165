import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AdminLandingPage from './components/Login/AdminLandingPage';
import VisitorLandingPage from './components/VisitorLandingPage';
import { Provider } from 'react-redux';
import store from "./store";
import TestimonialsMainPage from './components/TestimonialsMainPage';
import ContactMainPage from './components/ContactMainPage';
import GalleryMainPage from './components/GalleryMainPage';
import OurStoryMainPage from './components/OurStoryMainPage';
import ProcessMainPage from './components/ProcessMainPage';
import TheTeamPage from './components/TheTeamPage';

import { Helmet } from 'react-helmet'

function C2App() {
  return (
    <Provider store={store}>
    <BrowserRouter>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{"C2 Custom Building"}</title>
    </Helmet>
    <Routes>
      <Route path="/admin" element={<AdminLandingPage/>} />
      <Route path="/" element={<VisitorLandingPage/>} />
      <Route exact path='/home' element={<VisitorLandingPage/>} />
      <Route exact path='/gallery' element={<GalleryMainPage navBarClick={true} />} />
      <Route exact path='/testimonials' element={<TestimonialsMainPage/>} />
      <Route exact path='/contact' element={<ContactMainPage/>} />
      <Route exact path='/ourstory' element={<OurStoryMainPage/>} />
      <Route exact path='/process' element={<ProcessMainPage/>} />
      <Route exact path='/theteam' element={<TheTeamPage/>} />
    </Routes>
    </BrowserRouter>
    </Provider>
  );
}

export default C2App;
