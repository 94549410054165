import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography } from "@mui/material";
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { delGalleryCategory} from "../../../redux/actions/GalleryCategoryActions";
import GalleryCategorySelector from "./GalleryCategorySelector";

function GalleryCategoryDeleter(props){

    const dispatch = useDispatch();

    const [showDelWindow, setShowDelWindow] = useState(false);
    const [showConfirmDelWindow, setShowConfirmDelWindow] = useState(false);
    const [showNoSelectedIdError, setShowNoSelectedIdError] = useState(false);
    const selectedCategory = useSelector((state) => state.galleryCategoryReducer.selectedCategory);


  const handleDel = async () => {

    if(selectedCategory.id === ""){
      setShowNoSelectedIdError(true);
      return;
    }

    await dispatch(await delGalleryCategory(selectedCategory.id))
    setShowDelWindow(false);
    setShowConfirmDelWindow(false);
  }

  const handleClick = () => {
    setShowDelWindow(true);
  }

  const handleClose = () => {
    setShowDelWindow(false);
    setShowConfirmDelWindow(false);
    setShowNoSelectedIdError(false);
  }

  const showConfirmation = () => {
    setShowConfirmDelWindow(true);
  }
    
  const delWindow = <Dialog open={showDelWindow} onClose={handleClose}>
                      <DialogTitle>{"Delete a Gallery Category"}</DialogTitle>
                      <GalleryCategorySelector />
                      <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={showConfirmation}>{"Delete"}</Button>
                      </DialogActions>
                    </Dialog>
  
  const noIdSelectedErrorWindow = <Dialog open={showDelWindow} onClose={handleClose}>
                      <DialogTitle>{"Error Deleting Category"}</DialogTitle>
                      <DialogContent>
                              <Typography>No Gallery Category was Selected - please select a Gallery Category to Delete</Typography>
                            </DialogContent>
                      <DialogActions>
                      <Button onClick={handleClose}>Ok</Button>
                      </DialogActions>
                    </Dialog>



  const confirmDelWindow = <Dialog open={showConfirmDelWindow} onClose={handleClose}>
                            <DialogTitle>{"Add a Gallery Category"}</DialogTitle>
                            <DialogContent>
                              <Typography>Are you sure you want to delete this Gallery Category? This cannot be undone.</Typography>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDel}>{"Delete"}</Button>
                            </DialogActions>
                          </Dialog>

    return(
      <div>
       {showNoSelectedIdError ? noIdSelectedErrorWindow : <div /> }
       {showConfirmDelWindow ? confirmDelWindow : (showDelWindow ? delWindow : <Button sx={{height: "50px"}} variant="contained" onClick={handleClick}>Delete Gallery Category</Button>)}
      </div>
    );

}

export default GalleryCategoryDeleter;