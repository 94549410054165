import { GET_IMAGES_FOR_BUILD_ID_BEGIN, GET_IMAGES_FOR_BUILD_ID_SUCCESS, 
         GET_IMAGES_FOR_BUILD_ID_FAILURE, 
         GET_LANDING_PAGE_PHOTOS_FAILURE,
         GET_LANDING_PAGE_PHOTOS_SUCCESS,
         GET_LANDING_PAGE_PHOTOS_BEGIN,
         ADD_IMAGES_SUCCESS, RESET_LAST_PHOTOS_ADDED_METADATA, STORE_CACHED_IMAGES,
        CLEAR_LASTADDEDPHOTOS_MODE, UPLOAD_IMAGES_START, UPLOAD_IMAGES_SUCCESS, REM_IMAGES_SUCCESS} from '../actions/actionTypes';

const initialState = {
    imageCache: [],
    photosForSelectedBuild: [],
    landingPagePhotos: [],
    lastAddedPhotosMetaData: [],
    lastAddedPhotosMode: null,
    loading: false,
    errorMessage: null
};

export default function imageReducer(state = initialState, action) {

    switch(action.type) {
        case GET_LANDING_PAGE_PHOTOS_BEGIN:
        case GET_IMAGES_FOR_BUILD_ID_BEGIN:

            return {
                ...state
            };

        case GET_IMAGES_FOR_BUILD_ID_SUCCESS:

            return {
                ...state,
                photosForSelectedBuild: action.payload.photos,
                loading: false,
                errorMessage: null
            };

        case GET_LANDING_PAGE_PHOTOS_SUCCESS:

            return {
                ...state,
                landingPagePhotos: action.payload.photos,
                loading: false,
                errorMessage: null
            };

        case GET_LANDING_PAGE_PHOTOS_FAILURE:
        case GET_IMAGES_FOR_BUILD_ID_FAILURE:

            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case ADD_IMAGES_SUCCESS:
            
            return {
                ...state,
                lastAddedPhotosMetaData: action.payload.photosAdded,
                lastAddedPhotosMode: action.payload.mode
            };
        
        
        case RESET_LAST_PHOTOS_ADDED_METADATA:

            return {
                ...state,
                lastAddedPhotosMetaData: []
            }
        case STORE_CACHED_IMAGES:
            return {
                ...state,
                imageCache: action.payload.images
            }
        
        case CLEAR_LASTADDEDPHOTOS_MODE:
            return{
                ...state,
                lastAddedPhotosMode: null
            }
        
        case UPLOAD_IMAGES_START:
            return{
                ...state,
                loading: true
            }
        
        case UPLOAD_IMAGES_SUCCESS:
            return{
                ...state,
                loading: false
            }
        
        case REM_IMAGES_SUCCESS:
            
            return{
                ...state,
                landingPagePhotos: state.landingPagePhotos.filter(x => x !== action.payload.name),
                photosForSelectedBuild: state.photosForSelectedBuild.filter(x => x !== action.payload.name)
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}