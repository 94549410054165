import { ADD_CLIENT_SUCCESS, GET_CLIENTS_BEGIN, GET_CLIENTS_FAILURE, GET_CLIENTS_SUCCESS, SET_SELECTED_CLIENT, UPDATE_CLIENT_SUCCESS, SET_SELECTED_SUBFORM_CLIENT } from '../actions/actionTypes';

const initialState = {
    clients: [],
    selectedClient: null,
    selectedSubFormClient: null,
    loading: false,
    errorMessage: null
};

export default function clientReducer(state = initialState, action) {

    switch(action.type) {
        case GET_CLIENTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_CLIENTS_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                clients: action.payload.clients,
                loading: false,
                errorMessage: null
            };

        case GET_CLIENTS_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case SET_SELECTED_CLIENT:
            return {
                ...state,
                loading: false,
                selectedClient: action.payload.client
            };

        case SET_SELECTED_SUBFORM_CLIENT:
            return {
                ...state,
                loading: false,
                selectedSubFormClient: action.payload.client
            };

        case ADD_CLIENT_SUCCESS:

            let clientCopy = state.clients;
            clientCopy.push(action.payload.client);

            return {
                ...state,
                clients: clientCopy
            }
        case UPDATE_CLIENT_SUCCESS:

            console.log("Updating Selected Client to: " + JSON.stringify(action.payload.client))
            return {
                ...state,
                selectedClient: action.payload.client
            }
            

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}