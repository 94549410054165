import { SET_SESSION_TOKEN, UNSET_SESSION_TOKEN, SET_SESSION_EXPIRED } from '../actionTypes';
import { SessionConstants } from '../../../constants/SessionConstants';
import store from '../../../store';

export function setSessionToken(session_token) {
    return async dispatch => {
        if (!!!session_token) {
            throw new Error('Session Token is invalid: '+ session_token);
        }
        
        let expireMS = SessionConstants.NO_TIMEOUT_EXPIRATION_IN_MS;

        let expireEpoch = Date.now() + expireMS;
        let context = {
            session_token: session_token,
            expirationEpoch: expireEpoch
        };
        sessionStorage.setItem('session_token', JSON.stringify(context));
        dispatch(setSessionTokenInternal(session_token));
        dispatch(clearSessionTokenAfter(expireMS));
    };
}

export function restoreSessionToken() {
    return async dispatch => {
        try {
            let storedContext = JSON.parse(sessionStorage.getItem('session_token'));

            if (!!!storedContext) {
                throw new Error('No session context stored in sessionCache');
            }

            // Validate values match current state
            let state = store.getState();
            if (storedContext.isTrial !== state.sessionTypeReducer.isTrialSession) {
                console.warn('Discarding session context as the isTrial flag in the stored context (' + storedContext.isTrial + ') does not match the state of the application (' + state.sessionTypeReducer.isTrialSession + ')');
                return;
            }

            // As long as there is still time left in the session, we will restore it.
            // Otherwise, the sessionToken won't be set and the user will be asked to log in.
            if (storedContext.expirationEpoch > Date.now()) {
                let expireTimeout = storedContext.expirationEpoch - Date.now();
                dispatch(setSessionTokenInternal(storedContext.session_token));
                if (storedContext.isFullCustomer && storedContext.userWantsNoTimeout) {
                }
                dispatch(clearSessionTokenAfter(expireTimeout));
            } else {
                console.log('Stored session has expired');
            }
        } catch(e) {
            console.error('Object stored in sessionCache is malformed. Should not see this outside of a development-time error. Removing malformed object.', e);
            sessionStorage.removeItem('session_token');
        }
    };
}

export function unsetSessionToken() {
    return async dispatch => {
        sessionStorage.removeItem('session_token');
        dispatch(unsetSessionTokenInternal());
        clearSessionTokenTimeout();
    };
}

function internalChangeSessionTimeout(sessionContext, expireMS) {
    return async dispatch => {
        let expireEpoch =  Date.now() + expireMS;
        sessionContext.expirationEpoch = expireEpoch;
        sessionStorage.setItem('session_token', JSON.stringify(sessionContext));
        dispatch(clearSessionTokenAfter(expireMS));
    }
}

export function setSessionTokenNoTimeout() {
    return async dispatch => {
        let storedContext = JSON.parse(sessionStorage.getItem('session_token'));
        if (!!storedContext && storedContext.userWantsNoTimeout && !storedContext.isTrial) {
            // Update expiration to be 100 years
            dispatch(internalChangeSessionTimeout(storedContext, SessionConstants.NO_TIMEOUT_EXPIRATION_IN_MS));
            console.log('Updated current session to have indefinite length per user preference');
        }
    }
}

export function setSessionTokenMustTimeout(caller_reason) {
  return async dispatch => {
    let storedContext = JSON.parse(sessionStorage.getItem('session_token'));
    if (!!storedContext) {
      // Update expiration to be either trial timeout or login timeout
      let expireMS = storedContext.isTrial ? SessionConstants.TRIAL_EXPIRATION_IN_MS : SessionConstants.LOGIN_EXPIRATION_IN_MS;
      dispatch(internalChangeSessionTimeout(storedContext, expireMS));
      console.log('Updated current session to enforce session timeout. Reason: ' + caller_reason);
    }
  }
}

var sessionClearTimer = false;
function clearSessionTokenAfter(timeoutMillis) {
    return async dispatch => {
        if (!sessionClearTimer) {
            console.log('Scheduling a forced logout after ' + timeoutMillis + 'ms');
            // Create a new timer
            sessionClearTimer = setTimeout(() => {
                console.log('Session timeout has elapsed. Forcing logout');
                sessionClearTimer = false;
                dispatch(setSessionExpired());
                dispatch(unsetSessionToken());
            }, timeoutMillis);
        }
    }
}

function clearSessionTokenTimeout() {
    clearTimeout(sessionClearTimer);
}

// Originally used in the ExxoApp's "has browser tab closed" logic.
// No longer in use there, but kept in case its needed in the future
export function expireSessioneImmediately() {
    return async dispatch => {
        dispatch(setSessionExpired());
        dispatch(unsetSessionToken());
        dispatch(expireSessionOnBackend());
    }
}

function expireSessionOnBackend(){
    return async dispatch => {

        let state = store.getState();
        let sessionToken = state.sessionTokenReducer.session_token;

        return fetch(state.slotConfigurationStringsReducer.authenticationServiceUrl + '/Authentication/ExpireSession/' + sessionToken, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function(response) {
                if (response.status !== 200) {
                    
                } 
            });
    };
}

const setSessionTokenInternal = token => ({
    type: SET_SESSION_TOKEN,
    payload: { token }
})

const unsetSessionTokenInternal = () => ({
    type: UNSET_SESSION_TOKEN
})

const setSessionExpired = () => ({
    type: SET_SESSION_EXPIRED
})
