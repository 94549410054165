import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Footer";
import Contact from "./Contact";
import { Grid } from "@mui/material";
import buildwithimage from "../assets/buildinghome.jpg"
import warrrantyimage from "../assets/OIG.png"
import GeneralInquiryForm from "./GeneralInquiryForm";
import WarrantyClaimForm from "./WarrantyClaimForm";

import "./ContactMainPage.css";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function ContactMainPage(){

    return(
        <ThemeProvider theme={theme}>    
             <NavigationBar bgcolor="L"/>
             <div id="page-container">  
             <div id="content-wrap">
             <div className="contactList">
             <Grid alignItems={"center"} justifyContent={"center"} >
                <Grid item xl={true} align={"center"}>
                    <Contact form={<GeneralInquiryForm />}
                             text="Building with C2 Custom Homes" 
                             img={buildwithimage} buttonText="Contact Us" 
                             flavor="C2 Custom Homes is a premium builder.  We have refined not just the art of the build
                         to be cost effective yet swift, we have also refined the customer experience to be one you will
                          remember as a highlight of a lifetime"/>
                </Grid>
                <Grid item xl={true} align={"center"} paddingTop={"100px"}>
                    <Contact 
                             form={<WarrantyClaimForm />}
                             text="Industry leading 2-year Home Warranty" 
                             img={warrrantyimage} 
                             buttonText="File a Claim"
                             flavor="C2 Custom Homes offers an industry leading 2 year home warranty.  While we expect to hear from you
                             infrequently (if at all), we have you covered.  Need to file a warranty claim today?  Click below"/>
                </Grid>
                </Grid></div></div>
                <div id="footer"><Footer /></div>
                </div>
        </ThemeProvider>
    );
    
    }
    
export default ContactMainPage;