import { GET_CONFIG_BEGIN, GET_CONFIG_FAILURE, GET_CONFIG_SUCCESS, SET_USE_CATEGORIES_IN_GALLERY, SET_CONFIG_SUCCESS } from '../actions/actionTypes';

const initialState = {
    configuration: null,
    loading: false,
    errorMessage: null
};

export default function configReducer(state = initialState, action) {

    switch(action.type) {
        
        case SET_USE_CATEGORIES_IN_GALLERY:
            return {
                ...state,
                useCategoriesInGallery: action.payload.state
            };
        
        case GET_CONFIG_BEGIN:

            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_CONFIG_FAILURE:

            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case GET_CONFIG_SUCCESS:
        case SET_CONFIG_SUCCESS:
            return {
                ...state,
                configuration: action.payload.config
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}