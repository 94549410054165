import { LOGIN_BEGIN, LOGIN_FAILURE, LOGIN_SUCCESS  } from '../actionTypes';
import { setSessionToken } from './SessionTokenActions';
import store from '../../../store';
import { ConnectionStrings } from '../../../constants/ConnectionStrings';

export function loginUser(login_info) {
    return async dispatch => {

        let state = store.getState();

        dispatch(loginBeginInternal());

        return fetch(ConnectionStrings.LoginServer + "/C2Authentication/Login", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(login_info) // body data type must match "Content-Type" header
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(loginFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(loginFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let sessionToken = response?.sessionToken;
            if (sessionToken === null || sessionToken === undefined) {
                return null;
            } else {
                dispatch(loginSuccessInternal());
                dispatch(setSessionToken(sessionToken));
                return sessionToken;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(loginFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(loginFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const loginBeginInternal = () => ({
    type: LOGIN_BEGIN
});

const loginSuccessInternal = () => ({
    type: LOGIN_SUCCESS
});

const loginFailureInternal = errorMessage => ({
    type: LOGIN_FAILURE,
    payload: { errorMessage }
});