import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTestimonials } from "../redux/actions/TestimonialActions";
import { Grid, Card } from "@mui/material";
import TeamMember from "./TeamMember";


const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function TheTeamPage(){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchTestimonials();              
    }, [])
    
    const doFetchTestimonials = async () => {
      dispatch(getTestimonials());
    }

    const shannonBio =     <div>
                            <p>Shannon's journey through the Texas construction and real estate industry is impressive. Starting
                            in his hometown of Bellaire, he was immersed in the field from an early age, laying a solid
                            foundation for his career. His educational background from the University of Texas at Austin in
                            Economics and Business provided him with the essential knowledge to navigate the intricacies of
                            the industry.</p>

                            <p>His decade-long stint building new homes in Houston equipped him with invaluable hands-on
                            experience, which he then refined over the next 15 years by specializing in custom building in
                            Austin. This specialized focus not only honed his skills but also allowed him to cater to the
                            unique desires and needs of his clients.</p>

                            <p>His tenure as the Division President for a major Texas custom home builder further solidified his
                            expertise and leadership in the industry. This role provided him with insights into operations,
                            management, and the finer details of large-scale custom projects.</p>

                            <p>Now, as the founder of C2 Custom Building, Shannon brings together all his experiences and
                            expertise to create exceptional dream homes for his clients. His reputation for excellence,
                            innovation, and client satisfaction speaks volumes about his commitment to his craft and the
                            quality of his work. With over two decades of dedication, Shannon stands as a beacon of quality
                            and reliability in the Texas custom home building scene.</p>

                            <p>In addition to his professional accomplishments, Shannon has also built a fulfilling personal life.
                            He has been happily married to Melissa Nelson for over 20 years, a testament to their strong
                            bond and commitment to each other. Together, they have raised two boys, adding depth and
                            richness to their family life.</p>

                            <p>Balancing the demands of a successful career with the joys and responsibilities of family life
                            showcases Shannon's ability to excel in multiple facets of his life. This commitment to both his
                            professional and personal endeavors underscores his dedication, integrity, and balance as an
                            individual.</p>
                            </div>

    const jimBio =         <div><p>Jim specializes as a Build On Your Lot Consultant, a niche market that requires a lot of
                            expertise and knowledge. He understands the challenges and complexities of buying a
                            lot in Austin and Central Texas (Hill Country) where the land has diverse and difficult
                            topography.</p>

                            <p>Jim’s personal and professional journey is also impressive and inspiring. He grew up in
                            Richardson, Texas. He spent his high school summers working and building cabins at
                            the A-Bar-A Ranch, a 130,000-acre dude ranch in Encampment, Wyoming. He attended
                            the University of Wyoming for two years, and then completed his Bachelor’s Degree in
                            Business Administration with an emphasis in Business Management at Texas State
                            University in San Marcos. He got his Texas Real Estate License and worked as
                            Production Supervisor for Ryland Homes, where he was transferred to Austin for a year
                            to start a new division. He then moved to Memphis, Tennessee, where he started his
                            own homebuilding company and became an Award-Winning Builder. He took two
                            homebuilding companies to the #10 and #14 top rankings in the Memphis/Shelby
                            County areas, and won 8 out of 10 awards at the Vesta Home Show, an annual event
                            that attracts over 30,000 visitors. He has been married to his wife, Kate, for over 40
                            years, and they have raised three children.</p>

                            <p>Jim is passionate about his work, and he is always ready to go the extra mile for his
                            clients. He has a wealth of experience and knowledge in all aspects of the real estate
                            industry, that clients will greatly benefit.</p></div>

    const codyBio = <div>
                            <p>Cody Metcalf is a seasoned project manager with a wealth of experience in the construction
                            industry. Starting his journey in Northwest Texas, Cody's career has been marked by dedication,
                            skill, and a commitment to excellence. Graduating from Texas State University in 1996, he
                            quickly immersed himself in the world of construction, laying the foundation for what would
                            become a remarkable career.</p>

                            <p>His time in Houston saw him building houses and gaining invaluable hands-on experience. This
                            period was crucial in shaping his expertise across various aspects of construction. Notable
                            highlights include being recognized as the GHBA Builder of the Year in 2013 and securing the
                            runner-up title for Builder of the Year in Austin, Texas in 2017. These accolades underscore
                            Cody's reputation as a leader in the field, celebrated for his dedication and craftsmanship.</p>

                            <p>As a project manager at C2 Custom Building, Cody brings his years of experience to bear,
                            overseeing diverse construction projects with precision and efficiency. His hands-on approach,
                            combined with technical know-how, ensures that every project meets and exceeds expectations.
                            Outside of work, Cody finds solace and enjoyment in his personal pursuits. Whether it's driving
                            old hot rods, spending time by the lake, or engaging in a friendly game of disc golf, he embraces
                            life's simple pleasures with enthusiasm. Guided by a philosophy centered on exceeding
                            expectations, Cody approaches each project with integrity and professionalism, aiming to deliver
                            results that surpass clients' highest standards.</p>

                            <p>In his personal life, Cody cherishes time with his wife Liz and their family, finding fulfillment in
                            their shared experiences and bonds. As he looks ahead, Cody remains dedicated to pushing the
                            boundaries of construction, driven by a passion for building and a commitment to leaving a
                            lasting legacy of excellence.</p></div>
                            


    return(
        <ThemeProvider theme={theme}>  

             <NavigationBar bgcolor="L"/>
             <div id="page-container">  
             <div id="content-wrap">
             <div className="ourstoryList">
             <Grid xs={12} alignItems={"center"} justifyContent={"center"} >
                <Grid item align={"center"}>
                    <TeamMember fullName="Shannon Nelson" title="President, Owner" pic={""} bio={shannonBio} />
                </Grid>
                <Grid item align={"center"}>
                    <TeamMember fullName="Jim McCauley" title="BYOL Consultant" pic={""} bio={jimBio} />
                </Grid>
                <Grid item align={"center"}>
                    <TeamMember fullName="Cody Metcalf" title="Project Manager" pic={""} bio={codyBio} />
                </Grid>
             </Grid></div></div>
             <div id="footer">
             <Footer /></div>
        </div>
        </ThemeProvider>
    );
    
    }
    
export default TheTeamPage;