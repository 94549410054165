import store from '../../store';
import { ConnectionStrings } from '../../constants/ConnectionStrings';
import { GET_BUILDS_BEGIN, GET_BUILDS_FAILURE, GET_BUILDS_SUCCESS, 
         SET_SELECTED_BUILD, GET_CATEGORY_BUILDS_BEGIN, GET_CATEGORY_BUILDS_FAILURE, 
         GET_CATEGORY_BUILDS_SUCCESS, ADD_TO_BUILDS, ADD_BUILD_BEGIN, ADD_BUILD_FAILURE, 
         ADD_BUILD_SUCCESS, UPDATE_BUILD_BEGIN, UPDATE_BUILD_SUCCESS, UPDATE_BUILD_FAILURE,
        DEL_BUILD_BEGIN, DEL_BUILD_SUCCESS, DEL_BUILD_FAILURE, SET_ADDEDIT_BUILD_NAME } from './actionTypes';

export function getBuilds() {
    return async dispatch => {

        let state = store.getState();

        dispatch(getBuildsBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetBuilds/" + state.sessionTokenReducer.session_token, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getBuildsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getBuildsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let builds = response?.builds
            if (builds === null || builds === undefined) {
                return null;
            } else {
                dispatch(getBuildsSuccessInternal(builds));
                return builds;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getBuildsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getBuildsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export const setSelectedBuild = (build) => ({
    type: SET_SELECTED_BUILD,
    payload: {build}
});

const getBuildsBeginInternal = () => ({
    type: GET_BUILDS_BEGIN,

});

const getBuildsSuccessInternal = (builds) => ({
    type: GET_BUILDS_SUCCESS,
    payload: { builds }
});

const getBuildsFailureInternal = errorMessage => ({
    type: GET_BUILDS_FAILURE,
    payload: { errorMessage }
});

export const addToBuildsList = (builds) => ({
    type: ADD_TO_BUILDS,
    payload: { builds }
});

export const setAddEditBuildName = name => ({
    type: SET_ADDEDIT_BUILD_NAME,
    payload: { name }
})


export function getCategoryBuilds(categoryId) {
    return async dispatch => {

        dispatch(getCategoryBuildsBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetCategoryBuilds/", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({Id: categoryId})
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getCategoryBuildsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getCategoryBuildsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let builds = response?.builds
            if (builds === null || builds === undefined) {
                return null;
            } else {
                //dispatch(getCategoryBuildsSuccessInternal(builds));
                return builds;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getCategoryBuildsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getCategoryBuildsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}



const getCategoryBuildsBeginInternal = () => ({
    type: GET_CATEGORY_BUILDS_BEGIN,

});

const getCategoryBuildsSuccessInternal = (builds) => ({
    type: GET_CATEGORY_BUILDS_SUCCESS,
    payload: { builds }
});

const getCategoryBuildsFailureInternal = errorMessage => ({
    type: GET_CATEGORY_BUILDS_FAILURE,
    payload: { errorMessage }
});

export function updateBuild(buildblob) {
    return async dispatch => {

        dispatch(updateBuildsBeginInternal());

        let state = store.getState();

        buildblob["SessionToken"] = state.sessionTokenReducer.session_token;

        return fetch(ConnectionStrings.Services + "/C2Core/UpdateBuild/", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(buildblob)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(updateBuildsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(updateBuildsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let builds = response?.builds
            if (builds === null || builds === undefined) {
                return null;
            } else {
                dispatch(updateBuildsSuccessInternal(buildblob));
                return builds;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting build update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(updateBuildsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(updateBuildsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const updateBuildsBeginInternal = () => ({
    type: UPDATE_BUILD_BEGIN,

});

const updateBuildsSuccessInternal = (build) => ({
    type: UPDATE_BUILD_SUCCESS,
    payload: { build }
});

const updateBuildsFailureInternal = errorMessage => ({
    type: UPDATE_BUILD_FAILURE,
    payload: { errorMessage }
});

export function addBuild(buildblob) {
    return async dispatch => {

        dispatch(addBuildsBeginInternal());

        let state = store.getState();


        buildblob["SessionToken"] = state.sessionTokenReducer.session_token;

        return fetch(ConnectionStrings.Services + "/C2Core/AddBuild/", {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(buildblob)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(addBuildsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(addBuildsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(async function(response) {
            if(response == null){
                return null;
            } else {
                buildblob["id"] = response.id;

                let newBuilds = [];
                newBuilds.push(buildblob);

                dispatch(await addBuildsSuccessInternal(newBuilds));
                dispatch(setSelectedBuild(buildblob))
                return response.id;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting build update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(addBuildsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(addBuildsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const addBuildsBeginInternal = () => ({
    type: ADD_BUILD_BEGIN,

});

const addBuildsSuccessInternal = (builds) => ({
    type: ADD_BUILD_SUCCESS,
    payload: { builds }
});

const addBuildsFailureInternal = errorMessage => ({
    type: ADD_BUILD_FAILURE,
    payload: { errorMessage }
});

export function delBuild(id) {
    return async dispatch => {

        dispatch(delBuildsBeginInternal());

        let state = store.getState();

        let delBuildDto = {};

        delBuildDto["SessionToken"] = state.sessionTokenReducer.session_token;
        delBuildDto["Id"] = id;

        return fetch(ConnectionStrings.Services + "/C2Core/RemoveBuild/", {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(delBuildDto)
            
        }).then(function(response) {
            if (response.status === 200) {
                return null;
                
            } else if (response.status === 401) {
                dispatch(delBuildsFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(delBuildsFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function() {
                dispatch(delBuildsSuccessInternal(id));
                
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting build update", error);
            if (error.message == 'Failed to fetch') {
                dispatch(delBuildsFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(delBuildsFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const delBuildsBeginInternal = () => ({
    type: DEL_BUILD_BEGIN,

});

const delBuildsSuccessInternal = (id) => ({
    type: DEL_BUILD_SUCCESS,
    payload: { id }
});

const delBuildsFailureInternal = errorMessage => ({
    type: DEL_BUILD_FAILURE,
    payload: { errorMessage }
});