import store from '../../store';
import { ConnectionStrings } from '../../constants/ConnectionStrings';
import { GET_GC_BEGIN, GET_GC_FAILURE, GET_GC_SUCCESS, SET_SELECTED_CATEGORY, 
    ADD_CATEGORY_BUILDS_BEGIN, ADD_CATEGORY_BUILDS_FAILURE, ADD_CATEGORY_BUILDS_SUCCESS,
    DEL_CATEGORY_BUILDS_BEGIN, DEL_CATEGORY_BUILDS_SUCCESS, DEL_CATEGORY_BUILDS_FAILURE } from './actionTypes';

export function getGalleryCategories() {
    return async dispatch => {

        dispatch(getGCBeginInternal());

        return fetch(ConnectionStrings.Services + "/C2Core/GetGalleryCategories/", {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getGCFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getGCFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let categories = response?.categories
            if (categories === null || categories === undefined) {
                return null;
            } else {
                dispatch(getGCSuccessInternal(categories));
                return categories;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getGCFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(getGCFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const getGCBeginInternal = () => ({
    type: GET_GC_BEGIN,

});

const getGCSuccessInternal = (categories) => ({
    type: GET_GC_SUCCESS,
    payload: { categories }
});

const getGCFailureInternal = errorMessage => ({
    type: GET_GC_FAILURE,
    payload: { errorMessage }
});

export const setSelectedCategory = (category) => ({
    type: SET_SELECTED_CATEGORY,
    payload: { category }
})

export function addGalleryCategory(name) {
    return async dispatch => {

        dispatch(addGCBeginInternal());

        let state = store.getState();

        let dto = {SessionToken: state.sessionTokenReducer.session_token,
                   Name: name};

        return fetch(ConnectionStrings.Services + "/C2Core/AddGalleryCategory/", {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                dispatch(addGCSuccessInternal({Id: response.json().id, Name: name}));
            } else if (response.status === 401) {
                dispatch(addGCFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(addGCFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(addGCFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(addGCFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const addGCBeginInternal = () => ({
    type: ADD_CATEGORY_BUILDS_BEGIN,

});

const addGCSuccessInternal = (newCategory) => ({
    type: ADD_CATEGORY_BUILDS_SUCCESS,
    payload: { newCategory }
});

const addGCFailureInternal = errorMessage => ({
    type: ADD_CATEGORY_BUILDS_FAILURE,
    payload: { errorMessage }
});

export function delGalleryCategory(id) {
    return async dispatch => {

        dispatch(delGCBeginInternal());

        let state = store.getState();

        let dto = {SessionToken: state.sessionTokenReducer.session_token,
                   Id: id};

        return fetch(ConnectionStrings.Services + "/C2Core/RemoveGalleryCategory/", {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                dispatch(delGCSuccessInternal(id));
            } else if (response.status === 401) {
                dispatch(delGCFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(delGCFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(delGCFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(delGCFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const delGCBeginInternal = () => ({
    type: DEL_CATEGORY_BUILDS_BEGIN,

});

const delGCSuccessInternal = (id) => ({
    type: DEL_CATEGORY_BUILDS_SUCCESS,
    payload: { id }
});

const delGCFailureInternal = errorMessage => ({
    type: DEL_CATEGORY_BUILDS_FAILURE,
    payload: { errorMessage }
});