import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove'
import { Dialog, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ImageUploader from '../Images/ImageUploader';
import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageMetaDataCollector from './ImageMetaDataCollector';
import { uploadImages, removeImage } from '../../../redux/actions/ImageActions';

const PhotoAdderEditor = (props) => {

  const dispatch = useDispatch();

  const isEdit = props.mode === "Edit";

  const images = useSelector((state) => state.imageUploaderReducer.images);
  const lastAddedPhotosMetaData = useSelector((state) => state.imageReducer.lastAddedPhotosMetaData);
  const singleImage = isEdit ? images.filter(x => x.name === props.imageFileNameToEdit) : images;

  const [showForm, setShowForm] = useState(false);
  const [showMetaDataCollector, setShowMetaDataCollector] = useState(false);
  const [showConfirmDeleteImageForm, setShowConfirmDeleteImageForm] = useState(false)

  /*useEffect(() => { 

    if(lastAddedPhotosMetaData.length !== 0){

      let addImageBlob = {};

      if(props.mode === "Add"){
          addBuildBlob["Photos"] = lastAddedPhotosMetaData;
          dispatch(addBuild(addBuildBlob))
      }
    }
  }, [lastAddedPhotosMetaData])*/


  const handleClose = () => {
    setShowForm(false)
    setShowConfirmDeleteImageForm(false)
  };

  const handleImageMetaDataClose = () => {
      setShowMetaDataCollector(false)
  }

  const handleAddEdit = async () => {
    await dispatch(uploadImages());    

    if(images.length !== 0){
      setShowMetaDataCollector(true);
    }

    setShowForm(false);
  }

  const handleDelete = async () => {
    await dispatch(removeImage(props.buildId, props.photoName))
    setShowConfirmDeleteImageForm(false)
}

  const handleClick = () => {

    if(props.mode === "Del"){
      setShowConfirmDeleteImageForm(true);
    }else if(props.mode === "Add"){
      setShowForm(true)

    }else if(isEdit){
      setShowMetaDataCollector(true);
    }
  }

  const confirmDeleteImageForm = <Dialog open={showConfirmDeleteImageForm} onClose={handleClose}>
                                          <DialogTitle></DialogTitle>
                                          <DialogContent>
                                            <Typography>Are you CERTAIN you want to delete this Image?  It cannot be undone.
                                            </Typography>
                                          </DialogContent>
                                          <DialogActions>
                                          <Button onClick={handleDelete}>YES</Button>
                                          <Button onClick={handleClose}>NO</Button>
                                          </DialogActions>
                                        </Dialog>

  const photoAddEditForm = <Dialog open={showForm} onClose={handleClose}>
                                <DialogTitle>{props.mode === "Add" ? "Add" : "Edit"}{" A Photo"}</DialogTitle>
                                <DialogContent>
                                    <ImageUploader />
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleAddEdit}>{props.mode === "Add" ? "Add" : "Edit"}</Button>
                                </DialogActions>
                            </Dialog>


    

  return(
          <div>
            <div onClick={() => handleClick()}>{props.mode === "Add" ? <AddIcon /> : isEdit ? <EditIcon /> : <RemoveIcon />}</div>
            {showForm ? photoAddEditForm : <div />}
            {showConfirmDeleteImageForm ? confirmDeleteImageForm : <div />}
            {showMetaDataCollector ? <ImageMetaDataCollector mode={isEdit ? "Edit" : "Add"} source={"Image"} images={isEdit ? new Array({name: props.imageFileNameToEdit}) : images} closeFunc={handleImageMetaDataClose}/> : <div></div>}
          </div> 
  );
}

export default PhotoAdderEditor;