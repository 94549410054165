import {SET_IMAGES_FOR_UPLOAD, UPLOAD_IMAGES_FAILURE, UPLOAD_IMAGES_SUCCESS, 
         UPLOAD_IMAGES_START, GET_IMAGES_FOR_BUILD_ID_BEGIN, GET_IMAGES_FOR_BUILD_ID_SUCCESS,
         GET_IMAGES_FOR_BUILD_ID_FAILURE, ADD_IMAGES_BEGIN, ADD_IMAGES_FAILURE, ADD_IMAGES_SUCCESS,
         GET_LANDING_PAGE_PHOTOS_BEGIN, GET_LANDING_PAGE_PHOTOS_SUCCESS, GET_LANDING_PAGE_PHOTOS_FAILURE, 
         RESET_LAST_PHOTOS_ADDED_METADATA, STORE_CACHED_IMAGES, CLEAR_LASTADDEDPHOTOS_MODE,
         REM_IMAGES_BEGIN, REM_IMAGES_SUCCESS, REM_IMAGES_FAILURE} from "./actionTypes";
import { ConnectionStrings } from '../../constants/ConnectionStrings';
import store from "../../store";

export const setImagesForUpload = (images) => ({
    type: SET_IMAGES_FOR_UPLOAD,
    payload: {images}
});

export function getLandingPagePhotos(){
    return async dispatch => {

        dispatch(getLandingPagePhotosBegin());

        return fetch(ConnectionStrings.Services + "/C2Core/GetLandingPagePhotos", {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin'
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getLandingPagePhotosFailure("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getLandingPagePhotosFailure("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let photos = response?.photos
            if (photos === null || photos === undefined) {
                return null;
            } else {
                dispatch(getLandingPagePhotosSuccess(photos));
                return photos;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getLandingPagePhotosFailure("Authentication service is unavailable"));
            } else {
                dispatch(getLandingPagePhotosFailure("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

const getLandingPagePhotosBegin = () => ({
    type: GET_LANDING_PAGE_PHOTOS_BEGIN,

});

const getLandingPagePhotosSuccess = (photos) => ({
    type: GET_LANDING_PAGE_PHOTOS_SUCCESS,
    payload: { photos }
});

const getLandingPagePhotosFailure = errorMessage => ({
    type: GET_LANDING_PAGE_PHOTOS_FAILURE,
    payload: { errorMessage }
});


export function getImagesForBuildId(buildId){
    return async dispatch => {

        let state = store.getState();

        dispatch(getImagesForBuildIdBegin());

        var bodyData = {"Id": !buildId ? state.buildReducer.selectedBuild.id : buildId}

        return fetch(ConnectionStrings.Services + "/C2Core/GetPhotosForBuild", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(bodyData)
            
        }).then(function(response) {
            if (response.status === 200) {
                // Get the body as json, which will be our session_token
                return response.json();
            } else if (response.status === 401) {
                dispatch(getImagesForBuildIdFailure("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(getImagesForBuildIdFailure("Unable to authenticate user"));
                return null;
            }
        }).then(function(response) {
            if(response == null){
                return null;
            }
            let photos = response?.photos
            if (photos === null || photos === undefined) {
                return null;
            } else {
                dispatch(getImagesForBuildIdSuccess(photos));
                return photos;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            if (error.message == 'Failed to fetch') {
                dispatch(getImagesForBuildIdFailure("Authentication service is unavailable"));
            } else {
                dispatch(getImagesForBuildIdFailure("Unexpected exception while attempting login"));
            }
            return null;
        });
    };
}

export function uploadImages() {

    return async dispatch => {

        let state = store.getState();

        let numImages = state.imageUploaderReducer.images.length;
        let counter = 0;
        let runningSize = 0;
        let imagesSubset = [];
        const MAX_TRANSFER_SIZE = 30000000;

        dispatch(uploadImagesBeginInternal());

        while(counter < numImages){

            runningSize += state.imageUploaderReducer.images[counter].size;

            if(runningSize < MAX_TRANSFER_SIZE){
                imagesSubset.push(state.imageUploaderReducer.images[counter])
            }else{
                await uploadBatchOfImages(imagesSubset, dispatch);
                runningSize = 0;
                counter--;
                imagesSubset = [];
            }

            counter++;
        }

        if(imagesSubset.length > 0){
            await uploadBatchOfImages(imagesSubset, dispatch);
        }

        dispatch(uploadImagesSuccessInternal())
    };
}

function uploadBatchOfImages(images, dispatch){


        var formdata = new FormData();
        images.forEach(i => { 
            formdata.append("fileDetails", i); 
        })

        return fetch(ConnectionStrings.Services + "/C2Core/PostMultipleFile/", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            contentType: 'multipart/form-data',
            credentials: 'same-origin',
            body: formdata
            
        }).then(function(response) {
            if (response.status === 200) {
            } else if (response.status === 401) {
                dispatch(uploadImagesFailureInternal("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(uploadImagesFailureInternal("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting file upload", error);
            if (error.message == 'Failed to fetch') {
                dispatch(uploadImagesFailureInternal("Authentication service is unavailable"));
            } else {
                dispatch(uploadImagesFailureInternal("Unexpected exception while attempting login"));
            }
            return null;
        });

}

const uploadImagesBeginInternal = () => ({
    type: UPLOAD_IMAGES_START,

});

const uploadImagesSuccessInternal = () => ({
    type: UPLOAD_IMAGES_SUCCESS
});

const uploadImagesFailureInternal = errorMessage => ({
    type: UPLOAD_IMAGES_FAILURE,
    payload: { errorMessage }
});

const getImagesForBuildIdBegin = () => ({
    type: GET_IMAGES_FOR_BUILD_ID_BEGIN,

});

const getImagesForBuildIdSuccess = (photos) => ({
    type: GET_IMAGES_FOR_BUILD_ID_SUCCESS,
    payload: { photos }
});

const getImagesForBuildIdFailure = errorMessage => ({
    type: GET_IMAGES_FOR_BUILD_ID_FAILURE,
    payload: { errorMessage }
});

export const clearLastAddedPhotosMode = () => ({
    type: CLEAR_LASTADDEDPHOTOS_MODE
})

export function addImagesForBuild(images, mode){
    return async dispatch => {
        dispatch(addImagesSuccess(images, mode));     
    };
}

export function addImages(images){
    return async dispatch => {

        dispatch(addImagesBegin());

        let state = store.getState();

        images.forEach(i => { 
            i["BuildId"] = state.buildReducer.selectedBuild.id 
        })

        let dto = {SessionToken: state.sessionTokenReducer.session_token,
                    Photos: images}
        
        return fetch(ConnectionStrings.Services + "/C2Core/AddPhotos", {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                dispatch(addImagesSuccess(images, "ImageAdd"))
                dispatch(getImagesForBuildId(state.buildReducer.selectedBuild.id));
                return response.json();
            } else if (response.status === 401) {
                dispatch(addImagesFailure("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(addImagesFailure("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting file upload", error);
            if (error.message == 'Failed to fetch') {
                dispatch(addImagesFailure("Authentication service is unavailable"));
            } else {
                dispatch(addImagesFailure("Unexpected exception while attempting login"));
            }
            return null;
        }); 
    };
}

const addImagesBegin = () => ({
    type: ADD_IMAGES_BEGIN,

});

const addImagesSuccess = (photosAdded, mode) => ({
    type: ADD_IMAGES_SUCCESS,
    payload: { photosAdded, mode }
});

const addImagesFailure = errorMessage => ({
    type: ADD_IMAGES_FAILURE,
    payload: { errorMessage }
});

export const resetLastPhotosAddedMetaData = () => ({
    type: RESET_LAST_PHOTOS_ADDED_METADATA
})

//The purpose of this function is to essentially load up all the gallary images into an array so they are preloaded
//when a user visits the website for a better user experience
export function cacheImages(){
    return async dispatch => {
    let state = store.getState();

    var photoFilenames = [];

    state.buildReducer.builds.forEach((build) => photoFilenames = photoFilenames.concat(build.photoFileNames));

    let images = [];

    photoFilenames.forEach((file) => {

        let img = new Image();
        img.src = `${ConnectionStrings.Services}\\${adjustSourceURI(file)}`;
        images.push({FileName: file, File: img})
    })

    //we store it in state if needed, but it is not currently directly accessed
    dispatch(storeCachedImages(images))
    }
}

const adjustSourceURI = (uri) => {

    if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
        return uri;
    }else{
        //Try to make it a jpg
        return uri+".jpg";
    }
}

export const storeCachedImages = (images) => ({
    type: STORE_CACHED_IMAGES,
    payload: { images }
})

export function removeImage(buildId, name){
    return async dispatch => {

        dispatch(removeImageBegin());

        let state = store.getState();

        let dto = {SessionToken: state.sessionTokenReducer.session_token,
                    BuildId: buildId,
                    Name: name}
        
        return fetch(ConnectionStrings.Services + "/C2Core/RemovePhoto", {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(dto)
            
        }).then(function(response) {
            if (response.status === 200) {
                dispatch(removeImagesSuccess(buildId, name))
            } else if (response.status === 401) {
                dispatch(removeImagesFailure("Username and/or Password not found"));
                return null;
            }else
            {
                dispatch(removeImagesFailure("Unable to authenticate user"));
                return null;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting file upload", error);
            if (error.message == 'Failed to fetch') {
                dispatch(removeImagesFailure("Authentication service is unavailable"));
            } else {
                dispatch(removeImagesFailure("Unexpected exception while attempting login"));
            }
            return null;
        });
    }
}

const removeImageBegin = () => ({
    type: REM_IMAGES_BEGIN,

});

const removeImagesSuccess = (buildId, name) => ({
    type: REM_IMAGES_SUCCESS,
    payload: { buildId, name }
});

const removeImagesFailure = errorMessage => ({
    type: REM_IMAGES_FAILURE,
    payload: { errorMessage }
});
