import { ConnectionStrings } from '../../constants/ConnectionStrings';

export function SendGeneralInquiry(blob) {
    return async dispatch => {

        return fetch(ConnectionStrings.LoginServer+ "/C2Authentication/GeneralInquiry", {
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(blob)
            
        }).then(function(response) {
            console.log("Response Status Code is: " + response.status)
            if (response.status === 200 || response.status === 204) {
                return true;
            } else if (response.status === 401) {
                return false;
            }else
            {
                return false;
            }
        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception", error);
            return false;
        });
    };
}

export function SendWarrantyClaim(blob) {
    return async dispatch => {

        return fetch(ConnectionStrings.LoginServer + "/C2Authentication/WarrantyInquiry", {
            method: "DELETE",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(blob)
            
        }).then(function(response) {
            if (response.status === 200 || response.status === 204) {
                return true;
            } else if (response.status === 401) {
                return false;
            }else
            {
                return false;
            }
        }).then(function(response) {
            if(response == null || response.id == null){
                return false;
            }
            return false;

        }).catch(function(error) {
            // Called when network endpoint is not available, or other kind of exception case
            console.error("Unexpected exception while attempting login", error);
            return false;
        });
    };
}