import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useState, useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClients, setSelectedClient, setSelectedSubFormClient } from "../../../redux/actions/ClientActions";

function ClientSelector(props, ref){

    const dispatch = useDispatch();
    const clients = useSelector((state) => state.clientReducer.clients);
    const noSelectedClient = { id: '' };
    const selectedClient = useSelector((state) => state.clientReducer.selectedClient);
    const selectedSubFormClient = useSelector((state) => state.clientReducer.selectedSubFormClient);
    const [selectedClientLocal, setSelectedClientLocal] = useState(props.isSubForm ? (props.isEdit ? selectedSubFormClient : noSelectedClient) : noSelectedClient);

    useEffect(() => {

          doFetchClients();
          if(props.isSubForm && props.isEdit){
            setSelectedClientLocal(selectedSubFormClient);
          }else if(!props.isSubForm){
            if(selectedClient){
              setSelectedClientLocal(selectedClient);    
            }   
          }
              
    }, [selectedClient, selectedSubFormClient])

    const doFetchClients = async () => {
        dispatch(await getClients());
      }

  const clientSelected = async (client) => {

    if(props.isSubForm){
      dispatch(setSelectedSubFormClient(client))
    }else{
      dispatch(setSelectedClient(client))
    }
    setSelectedClientLocal(client);

  }
    

    return(
    <FormControl sx={{ m: 1, minWidth: "80%" }}>
        <InputLabel id="demo-simple-select-helper-label">Client</InputLabel>
        <Select
          sx={{
            color: props.isSubForm ? 'black' : 'white',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: props.isSubForm ? 'black' : 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: props.isSubForm ? 'black' : 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: props.isSubForm ? 'black' : 'white',
            },
            '.MuiSvgIcon-root ': {
              fill: props.isSubForm ? "black !important" : "white !important",
            }
          }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={!selectedClientLocal ? noSelectedClient.id : selectedClientLocal.id}
          label="Build"
          inputRef={ref}
        >
          {clients?.map((client) =>
                    <MenuItem
                      value={client.id}
                      key={client.id}
                      onClick={() => {clientSelected(client)}}>
                        {client.firstName}{" "}{client.lastName} 
                    </MenuItem>
                  ) }
        </Select>
      </FormControl>
      );

}

export default forwardRef(ClientSelector);