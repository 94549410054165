import { GET_TESTIMONIALS_BEGIN, GET_TESTIMONIALS_FAILURE, GET_TESTIMONIALS_SUCCESS, SET_SELECTED_TESTIMONIAL } from '../actions/actionTypes';

const initialState = {
    testimonials: [],
    selectedTestimonial: null,
    loading: false,
    errorMessage: null
};

export default function testimonialReducer(state = initialState, action) {

    switch(action.type) {
        case GET_TESTIMONIALS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_TESTIMONIALS_SUCCESS:
            // All done: set loading "false".
            
            return {
                ...state,
                testimonials: action.payload.testimonials,
                loading: false,
                errorMessage: null
            };

        case GET_TESTIMONIALS_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case SET_SELECTED_TESTIMONIAL:
            console.log("Setting Testimonial: " + JSON.stringify(action.payload.testimonial))
            return {
                ...state,
                selectedTestimonial: action.payload.testimonial
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}