import { GET_BUILDS_BEGIN, GET_BUILDS_FAILURE, GET_BUILDS_SUCCESS, 
         SET_SELECTED_BUILD, GET_CATEGORY_BUILDS_BEGIN, GET_CATEGORY_BUILDS_FAILURE, 
         GET_CATEGORY_BUILDS_SUCCESS, ADD_BUILD_SUCCESS, DEL_BUILD_SUCCESS, ADD_TO_BUILDS, SET_ADDEDIT_BUILD_NAME } from '../actions/actionTypes';

const initialState = {
    builds: [],
    categoryBuilds: [],
    selectedBuild: null,
    currentAddEditBuildName: null,
    buildAdded: false,
    loading: false,
    errorMessage: null
};

export default function buildReducer(state = initialState, action) {

    switch(action.type) {
        case GET_BUILDS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_BUILDS_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                builds: action.payload.builds,
                loading: false,
                errorMessage: null
            };

        case GET_BUILDS_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };

        case GET_CATEGORY_BUILDS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                errorMessage: null
            };

        case GET_CATEGORY_BUILDS_SUCCESS:
            // All done: set loading "false".
            return {
                ...state,
                categoryBuilds: action.payload.builds,
                loading: false,
                errorMessage: null
            };

        case GET_CATEGORY_BUILDS_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage
            };
        case SET_SELECTED_BUILD:

            return {
                ...state,
                selectedBuild: action.payload.build
            };

        case ADD_BUILD_SUCCESS:
        case ADD_TO_BUILDS:

            //ensure only adding new builds
            let newBuilds = [];

            action.payload.builds.forEach(build => {

                if(state.builds.filter(b => b.id == build.id).length === 0)
                    newBuilds.push(build)
            })

            return {
                ...state,
                buildAdded: true,
                builds: state.builds.concat(newBuilds)
            }
        
        case DEL_BUILD_SUCCESS: 

            return {
                ...state,
                builds: state.builds.filter((build) => build.id != action.payload.id)
            }

        case SET_ADDEDIT_BUILD_NAME: 

            return {
                ...state,
                currentAddEditBuildName: action.payload.name
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}