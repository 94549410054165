import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBuilds, setSelectedBuild } from "../../../redux/actions/BuildActions";
import { setSelectedClient, setSelectedSubFormClient } from "../../../redux/actions/ClientActions";

function BuildSelector(){

    const dispatch = useDispatch();
    const builds = useSelector((state) => state.buildReducer.builds);
    const clients = useSelector((state) => state.clientReducer.clients);
    const selectedBuild = useSelector((state) => state.buildReducer.selectedBuild);

    const noSelectedBuild = { id: '' };
    const [selectedBuildLocal, setSelectedBuildLocal] = useState(noSelectedBuild);
    const [buildsLocal, setBuildsLocal] = useState(builds)

    const buildSelected = async (build) => {
      dispatch(setSelectedBuild(build));
      dispatch(setSelectedClient(clients.filter(c => c.id === build.clientId)[0]))
    }

    useEffect(() => {

      if(selectedBuild){
        setSelectedBuildLocal(selectedBuild)
        dispatch(setSelectedClient(clients.filter(c => c.id === selectedBuild.clientId)[0]))
        dispatch(setSelectedSubFormClient(clients.filter(c => c.id === selectedBuild.clientId)[0]))
      }
    }, [selectedBuild])
    
    useEffect(() => {

      setBuildsLocal(builds)

      let buildToSelect = builds[0];

      if(selectedBuild){
        buildToSelect = builds.filter(b => b.id === selectedBuild.id)[0];
      }

      dispatch(setSelectedBuild(buildToSelect))
    }, [builds])

    return(
      
    <FormControl sx={{ m: 1, minWidth: '80%' }}>
        <InputLabel id="demo-simple-select-helper-label">Build</InputLabel>
        <Select
          sx={{
            color: "white",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '.MuiSvgIcon-root ': {
              fill: "white !important",
            }
          }}

          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selectedBuildLocal.id }
          label="Build"
        >
          {console.log("Rendering....")}
          {buildsLocal?.map((build) =>
                    <MenuItem
                      value={build.id}
                      key={build.id}
                      onClick={() => {buildSelected(build)}}>
                        {build.name} 
                    </MenuItem>
                  ) }
        </Select>
      </FormControl>
      );

}

export default BuildSelector;


