import Carousel from 'react-material-ui-carousel'
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { ConnectionStrings } from '../constants/ConnectionStrings';
import { getLandingPagePhotos } from "../redux/actions/ImageActions";

import "../components/MainImageScroller.css"
import { getConfig } from '../redux/actions/ConfigActions';

function MainImageScroller(){

    const dispatch = useDispatch();
    const images = useSelector((state) => state.imageReducer.landingPagePhotos);
    const configuration = useSelector((state) => state.configReducer.configuration)
    const { height, width } = useWindowDimensions();

    useEffect(() => { 
        dispatch(getConfig())
        dispatch(getLandingPagePhotos());        
    }, [])

    const adjustSourceURI = (uri) => {

        if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
            return uri;
        }else{
            //Try to make it a jpg
            return uri+".jpg";
        }
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      
      function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }

    function Item(props)
    {
        return (
            <div className={width < 500 ? "MainImageMobile" : "MainImageDesktop"}>
                <div className="MainPageImage"><img src={`${ConnectionStrings.Services}\\${adjustSourceURI(props.item)}`} alt=""/></div>
                {/*<video autoplay>
                    <source src={ConnectionStrings.Services + "\\test.mkv"} />
                    Your browser does not support the video tag.
                </video>  */} 
            </div>
        )
    }

    return (
        <Carousel swipe={false} interval={configuration ? configuration["mainPageContentDuration"] : 5000}>
            {
                images.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )

}

export default MainImageScroller;