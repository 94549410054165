import { Grid, Box, Typography, Button, Menu, MenuItem, Icon } from "@mui/material";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import C2LogoW from "../../assets/C2LogoW.png";
import C2Logo from "../../assets/C2Logo.png";
import "../../components/NavigationBar/NavigationBar.css";
import MenuIcon from '@mui/icons-material/Menu';

function NavigationBar(props){

    const { height, width } = useWindowDimensions();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

      function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }

    const desktopNavBar = 
        <Box sx={{position: props.fixed ? "fixed" : "absolute", zIndex: "12", left: "50%", top: "0", transform: "translateX(-50%);", color: "white", overflow: "hidden"}}>
            <Grid  container
                spacing={5}
                paddingTop={2}
                wrap="nowrap"
                justifyContents="center"
                alignItems="center"
                marginLeft="0">
            <Grid item sx={{minWidth: "120px"}}>
                <Typography sx={{cursor: "pointer"}}>
                    <NavLink to="/home" 
                            activeClassName="active"
                            className={props.bgcolor === "D" ? "nlinkl" : "nlinkd"}
                            style={{ textDecoration: 'none'}}>
                        HOME
                    </NavLink>
                </Typography>
            </Grid>
            <Grid item sx={{minWidth: "120px"}}>
                <Typography sx={{cursor: "pointer"}}>
                    <NavLink to="/gallery" 
                            activeClassName="active"
                            className={props.bgcolor === "D" ? "nlinkl" : "nlinkd"}
                            style={{ textDecoration: 'none' }}>
                        GALLERY
                    </NavLink>
                </Typography>
            </Grid>
            <Grid item sx={{minWidth: "150px", cursor: "pointer"}}>
                <img width={75} height={75} src={props.bgcolor === "D" ? C2LogoW : C2Logo} alt=""></img>
            </Grid>
            <Grid item sx={{minWidth: "120px"}}>
                    <Button
                    disableRipple={true}
                    disableElevation={true}
                    sx={{color: props.bgcolor === "D" ? "white" : "black" , fontSize: "16px"}}
                    variant="text"
                    onMouseOver={handleClick}
                    //onMouseOut={handleClose}
                    >
                    ABOUT US 
                    </Button>
                    <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        onMouseLeave: handleClose,
                        'aria-labelledby': 'basic-button',
                    }}
                    >
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/ourstory" 
                            activeClassName="active"
                            className={"nlinkd"}
                            style={{ textDecoration: 'none' }}>
                            Our Story
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/process" 
                            activeClassName="active"
                            className={"nlinkd"}
                            style={{ textDecoration: 'none' }}>
                            Process
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/testimonials" 
                            activeClassName="active"
                            className={"nlinkd"}
                            style={{ textDecoration: 'none' }}>
                            Testimonials
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/theteam" 
                            activeClassName="active"
                            className={"nlinkd"}
                            style={{ textDecoration: 'none' }}>
                            The Team
                        </NavLink>
                    </MenuItem>
                    </Menu>
                </Grid>
                <Grid item sx={{minWidth: "120px"}}>
                    <Typography sx={{cursor: "pointer"}}>
                        <NavLink to="/contact" 
                                activeClassName="active"
                                className={props.bgcolor === "D" ? "nlinkl" : "nlinkd"}
                                style={{ textDecoration: 'none' }}>
                            CONTACT US
                        </NavLink>
                    </Typography>
                </Grid>
            </Grid>
        </Box>

    const mobileNavBar = <div className="mobileNavBar"><Button
                            disableRipple={true}
                            disableElevation={true}
                            sx={{color: "black"}}
                            variant="text"
                            onMouseOver={handleClick}
                            >
                            <MenuIcon color="black"/>
                            </Button>
                            <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                onMouseLeave: handleClose,
                                'aria-labelledby': 'basic-button',
                            }}
                            >
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/home" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    Home
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/gallery" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    Gallery
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/ourstory" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    Our Story
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/process" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    Process
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/testimonials" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    Testimonials
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/theteam" 
                                    activeClassName="active"
                                    className={"nlinkd"}
                                    style={{ textDecoration: 'none' }}>
                                    The Team
                                </NavLink>
                    </MenuItem>
                            </Menu><div className="mobileLogo"><img width={40} height={40} src={C2Logo} alt=""></img></div></div>

    return(<div> 
    {width < 600 ? mobileNavBar : desktopNavBar }
    </div>);

}

export default NavigationBar;