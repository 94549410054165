import { useDispatch } from "react-redux";
import MainImageScroller from "./MainImageScroller"
import NavigationBar from "./NavigationBar/NavigationBar";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import { useEffect } from "react";
import { cacheImages } from "../redux/actions/ImageActions";
import { getGalleryCategories } from "../redux/actions/GalleryCategoryActions";
import { getCategoryBuilds, addToBuildsList } from "../redux/actions/BuildActions";
import { OtherConstants } from "../constants/OtherConstants";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)


function VisitorLandingPage(){

    let dispatch = useDispatch();

    useEffect(() => { 

        const preLoadImages = async () => {
            await dispatch(await getGalleryCategories()).then( async (result) => {

                if(!result) return;

                result.forEach(async category => {

                    await dispatch(await getCategoryBuilds(category.id)).then(async (buildList) => {
                        await dispatch(await addToBuildsList(buildList))
                    });

                });
            });

            //Make sure to grab builds with No category ID assigned for the gallery page
            await dispatch(await getCategoryBuilds(OtherConstants.EMPTY_GUID)).then(async (buildList) => {
                await dispatch(await addToBuildsList(buildList))
            });
            
            dispatch(cacheImages());
        }

        preLoadImages();
    }, [])

    return(
        <ThemeProvider theme={theme}>    
            <NavigationBar bgcolor="D" fixed/>
            <MainImageScroller />
        </ThemeProvider>
    );
    
    }
    
export default VisitorLandingPage;