import { Card, CardMedia, CardContent, Typography, CardActions, Button } from '@mui/material';
import { useState, cloneElement } from 'react'

function Contact(props){

    const [showForm, setShowForm] = useState(false);

    const handleClick = () => {
        setShowForm(true)
    }

    const handleClose = () => {
        setShowForm(false)
    }

    const Form = () => { return cloneElement(props.form, { closeFunc: handleClose });}

    return(
        <div>
        <Card raised sx={{ minWidth: "30%", maxWidth: "40%", width: "35%"  }}>
            <CardMedia
                sx={{ height: 400, objectFit: "cover" }}
                image={props.img}
                title="Amazing Builders"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                {props.text}
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                    {props.flavor}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="medium" onClick={handleClick}>{props.buttonText}</Button>
            </CardActions>
        </Card>
        {showForm ? <Form /> : <div />}
        </div>
    )

}

export default Contact;