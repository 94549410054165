import { SET_SESSION_TOKEN, UNSET_SESSION_TOKEN, SET_SESSION_EXPIRED } from '../../actions/actionTypes';

const initialState = {
    session_token: null,
    session_expired: false
};

export default function sessionTokenReducer(state = initialState, action) {

    switch(action.type) {
        case SET_SESSION_TOKEN:
            return {
                ...state,
                session_token: action.payload.token
            };

        case UNSET_SESSION_TOKEN:
            return {
                ...state,
                session_token: null
            };

        case SET_SESSION_EXPIRED:
            return {
                ...state,
                session_expired: true
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}