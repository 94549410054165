import TestimonialSelector from "./TestimonialSelector";
import TestimonialAdderEditor from "./TestimonialAdderEditor";
import TestimonialInfo from "./TestimonialInfo";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonials } from "../../../redux/actions/TestimonialActions";
import { Box } from "@mui/material";



function TestimonialSubPanel(){

    const selectedTestimonial = useSelector((state) => state.testimonialReducer.selectedTestimonial);

    const dispatch = useDispatch();

    //TODO THIS NEEDS TO UPDATE ON ADD


    useEffect(() => {
        doFetchTestimonials();              
    }, [])

    const doFetchTestimonials = () => {
      dispatch(getTestimonials());
    }


    return(
    <Box border={1} borderRadius={2} sx={{margin: "5px", padding: "5px", height: "100%"}}>
        <Box sx={{display: "flex", justifyContent: "right", paddingRight: "10%"}}>
            <TestimonialAdderEditor mode="Add"/>
            <TestimonialAdderEditor mode="Edit"/>
            <TestimonialAdderEditor mode="Del"/>
        </Box>
        <TestimonialSelector />
        <TestimonialInfo />

    </Box>);

}

export default TestimonialSubPanel;