import { Dialog, TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionStrings } from '../../../constants/ConnectionStrings';
import { addImagesForBuild, addImages } from '../../../redux/actions/ImageActions';



function ImageMetaDataCollector(props){

    const stateImages = useSelector((state) => state.imageUploaderReducer.images);
    const images = props.images ? props.images : stateImages;
    const dispatch = useDispatch();
    const imageNameRefs = useRef([]);
    const imageRoomRefs = useRef([]);
    const imageDescriptionRefs = useRef([]);
    const imageFrontPageRefs = useRef([]);
    const imageTestimonialRefs = useRef([]);

    const handleImageMetaData = () => {

        let photoList = [];
        let indexToStartAt = imageNameRefs.current.length - props.images.length;
        let imgIdx = indexToStartAt;
  
        images.forEach((img) => {
  
          let photoMetaData = {Name: imageNameRefs.current[imgIdx]?.value,
                               FileName: img.name,
                               RoomDesignation: imageRoomRefs.current[imgIdx]?.value,
                               Description: imageDescriptionRefs.current[imgIdx]?.value,
                               IsForFrontPage: imageFrontPageRefs.current[imgIdx].checked,
                               IsForTestimonial: !props.forTestimonial ? false : imageTestimonialRefs.current[imgIdx].checked }
  
  
          photoList.push(photoMetaData);
          imgIdx++;
        })
  
        if(props.mode === "Add"){
          console.log("Add Invokation")
          if(props.source === "Build"){
            console.log("Build Invokation")
            dispatch(addImagesForBuild(photoList, props.mode))
          }else if(props.source === "Image"){
            console.log("Image Invokation")
            dispatch(addImages(photoList))
          }

        }else{
          console.log("Else Invokation")
            //Handle Edit
            //Make endpoint call and provide image filenames, endpoint should associate filenames with Build in DB
            //dispatch(updateBuild());
        }
  
        handleImageMetaDataClose()
      }

      const handleImageMetaDataClose = () => {
        props.closeFunc()
    }

    return(
        <Dialog open={true} onClose={handleImageMetaDataClose}>
        <DialogTitle>Please Provide some additional information per Image</DialogTitle>
        <DialogContent>
            {images.map((img) => (
              <div>
              <Box border={1} sx={{margin: "5px", padding: "5px"}}>
              <img 
                width="200px"
                height="113px"
                src={`${ConnectionStrings.Services}/${img.name}`}
                srcSet={`${ConnectionStrings.Services}/${img.name}`}
                alt={img.name}
                loading="lazy">
              </img>
              <TextField
                  inputRef={(element) => imageNameRefs.current.push(element)}
                  sx={{ m: 1, minWidth: "90%", color: "black" }}
                  id={img.name + "_Name"}
                  label={"Name"}
                  type="search"
                />
              <TextField
                  inputRef={(element) => imageRoomRefs.current.push(element)}
                  sx={{ m: 1, minWidth: "90%", color: "black" }}
                  id={img.name + "_Room"}
                  label={"Room"}
                  type="search"
                />
              <TextField
                  inputRef={(element) => imageDescriptionRefs.current.push(element)}
                  sx={{ m: 1, minWidth: "90%", color: "black" }}
                  id={img.name + "_Description"}
                  label={"Description"}
                  type="search"
                />
              <FormControlLabel control={<Checkbox inputRef={(element) => imageFrontPageRefs.current.push(element)} />} id={img.name + "_FrontPage"} label="Front Page Image" />
              {props.forTestimonial ? <FormControlLabel control={<Checkbox inputRef={(element) => imageTestimonialRefs.current.push(element)} />} id={img.name + "_Testimonial"} label="Testimonial Image" /> : <div></div>}
              </Box></div>

            ))}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleImageMetaDataClose}>Cancel</Button>
        <Button onClick={handleImageMetaData}>Submit</Button>
        </DialogActions>
        </Dialog>
      );

}

export default ImageMetaDataCollector;