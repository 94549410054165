import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTestimonials } from "../redux/actions/TestimonialActions";
import { Grid, Card } from "@mui/material";

import "./ProcessMainPage.css";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 13,
            fontWeight: 800
        },
    }
)

function ProcessMainPage(){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchTestimonials();              
    }, [])
    
    const doFetchTestimonials = async () => {
      dispatch(getTestimonials());
    }


    return(
        <ThemeProvider theme={theme}>  

             <NavigationBar bgcolor="L"/>
             <div id="page-container">  
             <div id="content-wrap">
             <div className="testimonialList">
             <Grid xs={12} alignItems={"center"} justifyContent={"center"} >
                <Grid item align={"center"}><Card sx={{width: "50%"}}>
                <p>1.	Initial Consultation: We meet with you to discuss your vision, budget, and timeline for your new home. This is where the magic begins!</p>
                <p>2.	Site Evaluation: The Lot Evaluation consists of walking the land, shooting the different levels to determine the foundation heights, which 
                    will help to understand the best house plan design and shape for the lot. We research if there is city water available or if there will be a need 
                    for a Water Well. We determine if there is Natural Gas or will it need a Propane Tank for your home. If City Sewer available or will the lot need a 
                    Septic System. Check for Electrical availability and what needs to be done to the land in preparation to build on. We will provide you with your own 
                    personal report that outlines the costs above in a budgeted format. This is crucial in helping our clients to make informed purchase and design decisions 
                    to meet their required budget or at least understand the costs involved.</p>
                <p>3.	Design: We work with you to design a custom home that meets your unique needs and preferences. We can work with your home plans, recommend an architect 
                    or introduce you to one of our experienced plan designers. We stay along your side to help consult you through the design process. After coming to a preliminary 
                    design, you then visit with our Interior Design team for a tour of the Design Center. There you will roughly view levels of material so we can come up with a profile
                     about your wants and desires. From there we can give you a good price on what it’s going to cost you to do the project. This is where your dream home starts to take shape!</p>
                <p>4.	Contract: Once all the costs are understood we move to the contract. At this point you will have a preliminary plan and contract to take to your lender to start the construction
                     loan process. Don’t worry, we have lenders that we can recommend who can help put all the necessary financing together. This is where the excitement really starts to build!</p>
                <p>5.	Permitting: While you are getting the financing in place and getting your selections done, we are doing a lot of behind-the-scenes work like obtaining all necessary permits and approvals from
                        local authorities. This is where the rubber meets the road!</p>
                <p>6.	Construction: We are excited to begin building your dream home! Once all financing is in place, selections are complete, and all governing authorities have given their 
                    approvals, we will start the construction process. Our team of experts will work tirelessly to ensure that your home is built to the highest standards.</p>
                <p>7.	Quality Control: We take pride in our work and conduct regular quality control checks throughout the construction process. Our team of experts will ensure that your home 
                    is built to the highest standards and that every detail is perfect</p>
                <p>8.	Final Inspection: We want to make sure that everything is perfect before we hand over the keys to your new home. That’s why we conduct a final inspection of your new home 
                    with you to ensure that we catch any problems. We want you to be completely satisfied with your new home.</p>
                <p>9.	Closing: Congratulations, your new home is ready! Once the final inspection items are complete and signed off along with all final payments, we will provide you with all 
                    necessary documentation and keys to your new home. We are confident that you will love your new home and we are excited to welcome you to the neighborhood! </p>
                <p>At C2 Custom Building, we are committed to providing you with a seamless and stress-free home building experience that exceeds your expectations. Our team of experienced professionals 
                    is dedicated to delivering high-quality workmanship that stands the test of time. We look forward to working with you to bring your dream home to life!</p>


                </Card></Grid>
                   

             </Grid></div></div>
             <div id="footer">
             <Footer /></div>
        </div>
        </ThemeProvider>
    );
    
    }
    
export default ProcessMainPage;