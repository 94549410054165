import ClientAdderEditor from "./ClientAdderEditor";
import ClientInfo from "./ClientInfo";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from "../../../redux/actions/ClientActions";
import { getBuilds } from "../../../redux/actions/BuildActions";
import { Box } from "@mui/material";
import ClientSelector from "./ClientSelector";



function ClientSubPanel(){

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchClients();              
    }, [])

    const doFetchClients = async () => {
      dispatch(await getClients());
      dispatch(await getBuilds());
    }


    return(
    <Box border={1} borderRadius={2} sx={{margin: "5px", padding: "5px", height: "100%"}}>
        <Box sx={{display: "flex", justifyContent: "right", paddingRight: "10%"}}>
            <ClientAdderEditor mode="Add"/>
            <ClientAdderEditor mode="Edit"/>
            <ClientAdderEditor mode="Del"/>
        </Box>
        <ClientSelector isSubForm={false} />
        <ClientInfo />

    </Box>);

}

export default ClientSubPanel;