import { Box } from '@mui/material';
import { ConnectionStrings } from '../constants/ConnectionStrings';
import "../components/SinglePhotoView.css"
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function SinglePhotoView(props){

    const close = () => {
        props.close();
    }

    const forward = () => {
        props.forward();
    }

    const backward = () => {
        props.back();
    }

    return(<div className="SingleImageLockout" >
                <div onClick={close}>
                    <CloseIcon style={{ zIndex: "1001",  color: 'white', fontSize: "40", position: "absolute", right: "50", top: "25", cursor:"pointer"}}/>
                </div>
                <div className="SingleImageLockoutImg">
                    <img src={props.photo} alt={props.photo}/>   
                </div>
                <div onClick={forward} >
                    <ArrowForwardIcon style={{ zIndex: "1001", color: 'white', fontSize: "40", position: "absolute", right: "0", top: "50%", cursor:"pointer"}}/>
                </div>
                <div onClick={backward} >
                    <ArrowBackIcon style={{ zIndex: "1001", color: 'white', fontSize: "40", position: "absolute", left: "0", top: "50%", cursor:"pointer"}} />
                </div>
           </div>
    )

}

export default SinglePhotoView;