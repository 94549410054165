import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Box, FormGroup, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { getConfig, setConfig } from '../../../redux/actions/ConfigActions';



function ConfigPanel(){

    const dispatch = useDispatch();
    const configuration = useSelector((state) => state.configReducer.configuration);
    const [newMPCDValue, setNewMPCDValue] = useState(configuration ? configuration["mainPageContentDuration"] : "5000")
    const newMPCDValueRef = useRef();

    useEffect(() => {
        dispatch(getConfig())           
    }, [])

    useEffect(() => {
        setNewMPCDValue(configuration ? configuration["mainPageContentDuration"] : "5000")      
    }, [configuration])

    const handleVideoOnMainPagedChecked = () => {
        let config = {...configuration, videoOnMainPageEnabled: configuration["videoOnMainPageEnabled"] === "1" ? "0" : "1"}
        dispatch(setConfig(config));
    }

    const handlegalleryCategoriesEnabledChecked = () => {
        let config = {...configuration, galleryCategoriesEnabled: configuration["galleryCategoriesEnabled"] === "1" ? "0" : "1"}
        dispatch(setConfig(config));
    }

    const handleSetMainPageContentDuration = (event, value) => {
        setNewMPCDValue(value);
        let config = {...configuration, mainPageContentDuration: newMPCDValueRef.current.value}
        dispatch(setConfig(config));
    }



    return(
    <Box border={1} borderRadius={2} sx={{display: "flex", margin: "5px", width: "100%"}}>
        <Box sx={{display: "flex", justifyContent: "right", paddingRight: "10%"}}>
        <FormGroup>
            <FormControlLabel disabled sx={{paddingLeft: "10px"}} control={<Checkbox onClick={handleVideoOnMainPagedChecked} checked={configuration ? configuration["videoOnMainPageEnabled"] === "1" : false}/>} label="Use Videos on Main Page" />
            <FormControlLabel sx={{paddingLeft: "10px"}} control={<Checkbox onClick={handlegalleryCategoriesEnabledChecked} checked={configuration ? configuration["galleryCategoriesEnabled"] === "1" : false}/>} label="Use Gallery Categories" />
            <FormControlLabel sx={{paddingLeft: "10px"}} control={<TextField inputRef={newMPCDValueRef} sx={{padding: "10px"}} onChange={handleSetMainPageContentDuration} value={newMPCDValue}/>} label="Main Page Content Duration" />
        </FormGroup>
        </Box>
    </Box>);

}

export default ConfigPanel;